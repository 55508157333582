import {
	REQUEST_TRAKYV_LIST_VELO, SET_TRAKYV_LIST_VELO_ERROR, RECEIVE_TRAKYV_LIST_VELO, ADD_TRAKYV_LIST_VELO,
	REQUEST_TRAKYV_CREATE_VELO, SET_TRAKYV_CREATE_VELO_ERROR, RECEIVE_TRAKYV_CREATE_VELO, ADD_TRAKYV_CREATED_VELO,
	UPDATE_VELO, SET_ERROR_MESSAGE_LIST_VELO, SET_LIST_VELO_ACHIEVED_REQUEST
} from '../actions/types';

const INITIAL_STATE = {
	isFetching: false, isInvalid: false, error: '', requestAchieved: false
};

const velo = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ADD_TRAKYV_CREATED_VELO:
		case UPDATE_VELO:
			return {
				...state, ...action.payload
			};
		default:
			return state;
	}
};

const listVelo = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_LIST_VELO_ACHIEVED_REQUEST:
			return {
				...state, requestAchieved: action.bool
			};
		case SET_ERROR_MESSAGE_LIST_VELO:
			return {
				...state, error: action.error
			};
		case REQUEST_TRAKYV_CREATE_VELO:
		case REQUEST_TRAKYV_LIST_VELO:
			return {
				...state, isFetching: true, isInvalid: false
			};
		case SET_TRAKYV_CREATE_VELO_ERROR:
		case SET_TRAKYV_LIST_VELO_ERROR:
			return {
				...state, isFetching: false, isInvalid: true
			};
		case RECEIVE_TRAKYV_CREATE_VELO:
		case RECEIVE_TRAKYV_LIST_VELO:
			return {
				...state, isFetching: false, isInvalid: false
			};
		case ADD_TRAKYV_CREATED_VELO:
			return {
				...state, [action.payload.deviceID]: velo(undefined, action)
			};
		case ADD_TRAKYV_LIST_VELO:
			return {
				...state,
				...action.devices
			};
		case UPDATE_VELO:
			return {
				...state,
				[action.selectedDeviceID]: velo(state[action.selectedDeviceID], action)
			};
		default:
			return state;
	}
};

export default listVelo;
