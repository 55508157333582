import React from 'react';

import {
	Page
} from 'react-onsenui';

import SignInForm from './SignInForm';

const SignIn = ({navigator}) => {
	return (
		<Page renderToolbar={() => null}>
			<SignInForm navigator={navigator} />
		</Page>
	);
};

export default SignIn;
