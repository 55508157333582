import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../actions';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import compose from 'recompose/compose';

import {List, Button, ListItem, ListHeader, Modal, Page} from 'react-onsenui';
import { lower, renderField, TrakyvFooter } from '../helpers';

import NavBar from './NavBar';

const handleFormSubmit = (props) => ({email, birth}) => {
	const {actions, navigator} = props;

	return actions.fetchResetTKPassword(JSON.stringify({'email': email, 'birth': birth}))
	.then(response => {
		actions.setResetTKPasswordSuccess();
	})
	.catch(error => console.log('Error while request pw reset', error));
};

const validate = (values) => {
	const errors = {};
	// email
	if (!values.email) {
		errors.email = 'Nécessaire';
	} else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(values.email)) {
		errors.email = 'Email non valable';
	}
	// birth
	if (!values.birth) {
		errors.birth = 'Nécessaire';
	} else if (!(/[1,2][9,0][0-9][0-9]-[0-1][0-9]-[0-3][0-9]/).test(values.birth)) {
		errors.birth = 'Le format doit être AAAA-MM-JJ (1957-05-20)';
	}
	return errors;
};

let Page_reset_password = (props) => {
	const {handleSubmit, valid, navigator, account, actions} = props;
	console.log(props);

	return (
		<Page
			renderToolbar={() => <NavBar title='Etape 1: Identification' navigator={navigator} backButton={true} />}
			renderModal={() => ( // Modal for account in creation (waiting for server answer)
				<Modal
					isOpen={account.isFetching || account.isInvalid || account.newPWRequested}
				>
					<div style={{display: 'flex', justifyContent: 'center'}}>
					{
						account.isFetching &&
						<section style={{margin: '16px'}}>
							<p style={{opacity: 0.6}}>
								Demande en cours, merci de patienter.
							</p>
						</section>
					}
					{
						account.newPWRequested &&
						<section style={{margin: '16px'}}>
							<p style={{opacity: 0.6}}>
								Demande aboutie, vérifier votre boîte email pour trouver la suite de la procédure.
							</p>
							<Button
								navigator={navigator}
								onClick={() => {
									actions.clearResetTKPasswordSuccess();
									navigator.popPage();
								}}
							>
								Retour
							</Button>
						</section>
					}
					{
						account.isInvalid &&
						<section style={{margin: '16px'}}>
							<p style={{opacity: 0.6}}>
								Vérifiez vos données et essayez à nouveau. Assurez-vous d'être connecté à internet.
							</p>
							<Button
								navigator={navigator}
								onClick={() => {
									actions.receiveResetTKPassword();
								}}
							>
								Retour
							</Button>
						</section>
					}
					</div>
				</Modal>)
			}
		>
			<List modifier='inset' style={{marginTop: '8px'}}
				renderHeader={() => <ListHeader style={{marginTop: '-8px'}}>
					Entrez votre email et date de naissance
				</ListHeader>}
			>
				<Field name='email' component={renderField} type='text' placeholder='E-mail' normalize={lower}/>
				<Field name='birth' component={renderField} type='date' placeholder='Date de naissance'/>
				<ListItem>
					<Button type='submit' modifier='large' disabled={!valid}
						navigator={navigator}
						onClick={handleSubmit(handleFormSubmit(props))}
					>
						Valider
					</Button>
				</ListItem>
			</List>
			<TrakyvFooter />
		</Page>
	);
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
Page_reset_password = reduxForm({
	form: 'resetPW', // a unique identifier for this form
  validate
})(Page_reset_password);

const selector = formValueSelector('resetPW');

const hoc = compose(
	connect(
		(state, props) => ({
			authenticated: state.auth.authenticated,
			account: state.account,
			fValues: selector(state, 'email', 'birth')
		}),
		(dispatch) => ({
			actions: bindActionCreators(Actions, dispatch)
		})
	)
);

export default hoc(Page_reset_password);
