import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../actions';
import values from 'lodash/values';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';

import {Icon, List, ListHeader, ListItem, Button, PullHook, Page} from 'react-onsenui';
import { VeloIcon, normalizePhone, TrakyvFooter } from '../helpers';

import NavBar from './NavBar';
import SignOutTBButton from './SignOutTBButton';

import { TRAKYV_STATE_SEEN,
	PAGE_PROFILE_EDITOR, PAGE_TRAKYVID_INPUT, PAGE_VELO_EDITOR } from '../constants';

import Page_profile_editor from './Page_profile_editor';
import Page_trakyvID_input from './Page_trakyvID_input';
import Page_velo_editor from './Page_velo_editor';

const getContent = (pullHook) => {
	switch (pullHook.state) {
		case 'initial':
			return 'Tirer pour recharger';
		case 'preaction':
			return 'Relâcher';
		case 'action':
			return 'Chargement...';
		default:
			return null;
	}
};

const StartPage = ({navigator, authenticated, account, listVelo, pullHook, handleChange, handleLoad, actions}) => {
	return (
		<Page
			onInit={() => {
				actions.fetchTKAccountProfile(navigator);
				actions.fetchTKListVelo();
			}}
			renderToolbar={() => <NavBar title='Profil & Vélos' navigator={navigator} backButton={false} right={
			<div>
			{
				authenticated ?
				<div >
					<SignOutTBButton key='b1' authenticated={authenticated} actions={actions} navigator={navigator}/>
				</div> :
				null
			}
			</div>
		}/>}
		>
			{
				<PullHook onChange={handleChange} onLoad={handleLoad}>
					{getContent(pullHook)}
				</PullHook>
			}
			<List modifier='inset' style={{marginTop: '8px'}}>
				<ListItem tappable
					onClick={() => {
						navigator.pushPage({component: Page_profile_editor, key: PAGE_PROFILE_EDITOR});
					}}>
					<div className='left'>
						<Icon style={{color: '#8CC919'}} size={{default: 40}} icon='ion-person'/>
					</div>
					<div className='center'>
						<div className='list__item__title'>
							{<div style={{fontWeight: 'bold'}}>{account.firstName} {account.lastName}</div>}
						</div>
						<div className='list__item__subtitle'>
							Email de login: {account.email} <br/>
							né le {account.birth} <br/>
							<div style={{display: 'flex'}}>
								<Icon style={{color: '#8CC919', marginRight: '5px'}} size={{default: 20}} icon='ion-iphone'/>
								<div>
									{normalizePhone(account.phone)}
								</div>
							</div>
						</div>
					</div>
					<div className='right'>
						<Icon icon='ion-chevron-right'/>
					</div>
				</ListItem>
			</List>
			<List modifier='inset' style={{marginTop: '8px'}}
				renderHeader={() => <ListHeader>
					Liste de vos vélos
				</ListHeader>}
				>
				{
					listVelo.length > 0 && values(listVelo).map(velo => (
						<ListItem tappable key={velo.deviceID}
								onClick={() => {
									actions.selectDeviceID(velo.deviceID);
									navigator.pushPage({component: Page_velo_editor, key: PAGE_VELO_EDITOR});
							}}>
								<div className='left'>
									<VeloIcon velo={velo} />
								</div>
								<div className='center'>
									<div className='list__item__title'>
										{<div style={{fontWeight: 'bold'}}>{velo.description}</div>}
									</div>
									<div className='list__item__subtitle'>
										Vélo créé le {new Date(velo.creationTime * 1000).toLocaleString()} <br/>
										Numéro Trakyv: {velo.uniqueID}
										{
											velo.isLost === TRAKYV_STATE_SEEN &&
											<div style={{fontStyle: 'italic'}}>
												Vélo vu à {velo.lastSeenAddress} <br/>
												le {new Date(velo.lastSeenTime * 1000).toLocaleString()}
											</div>
										}
									</div>
								</div>
								<div className='right'>
									<Icon icon='ion-chevron-right'/>
								</div>
							</ListItem>
						)
					)
				}
				<ListItem>
					<Button modifier='large' navigator={navigator}
						onClick={() => {
							navigator.pushPage({component: Page_trakyvID_input, key: PAGE_TRAKYVID_INPUT});
						}}>
						Aucun vélo? Ajoutez en un!
					</Button>
				</ListItem>
			</List>
			<TrakyvFooter />
		</Page>
	);
};

const hoc = compose(
	connect(
		(state, props) => ({
			authenticated: state.auth.authenticated,
			account: state.account,
			listVelo: values(state.listVelo).filter(el => typeof el === 'object' && el.deviceID)
		}),
		(dispatch) => ({
			actions: bindActionCreators(Actions, dispatch)
		})
	),
	withState(
		'pullHook',
		'setPullHook',
		{
			state: 'initial'
		}
	),
	withHandlers({
		handleChange: ({ setPullHook }) => (e) => {
			setPullHook({state: e.state});
		},
		handleLoad: ({ setPullHook, actions }) => (done) => {
			actions.fetchTKListVelo()
			.then(() => {
				done();
			});
		}
	})
);

export default hoc(StartPage);
