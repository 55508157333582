import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../actions';
import { Field, reduxForm } from 'redux-form';
import compose from 'recompose/compose';

import moment from 'moment';

import { PAGE_MAIN_FEATURES_MENU } from '../constants';

import {apiTKSignIn} from '../api';

import { List, Button, ListItem, Modal, Page} from 'react-onsenui';
import { lower, renderField, normalizePhone, calculateAge, TrakyvFooter } from '../helpers';

import NavBar from './NavBar';
import SignOutTBButton from './SignOutTBButton';
import MainPage from './MainPage';

const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
const isSafari = navigator.userAgent.match(/(Safari)|(Firefox)/i);

const handleFormSubmit = (props) => ({firstname, lastname, email, phone, password, birth, street, number, npa, city, country, language = 'fr', notes = ''}) => {
	const {actions, navigator} = props;
	// remove spaces from phone
	const filteredPhone = phone.replace(/ /g, '');

	return actions.fetchCreateTKAccount(JSON.stringify({'firstName': firstname, 'lastName': lastname, 'npa': npa, 'city': city, 'country': country, 'street': street, 'building': number, 'phone': filteredPhone, 'birth': isMobile ? birth : moment(birth).format('YYYY-MM-DD'), 'email': email, 'conditionsAccepted': true, 'language': language, 'notes': notes, 'password': password }))
	.then(() => {
		apiTKSignIn({email, password})
		.then((response) => {
			// 	save the Token and user info
			return actions.initRegisteredAccount(response);
		})
		.then(() => {
			// 	redirect to features
			navigator.resetPageStack([{ component: MainPage, key: PAGE_MAIN_FEATURES_MENU }]);
		})
		.catch(error => {
			console.log('error while creating account.', error.response);
		});
	});
};

const validate = (values) => {
	moment.locale('fr_CH');
	const errors = {};
	// firstname
	if (!values.firstname) {
		errors.firstname = 'Nécessaire';
	} else if (values.firstname.length < 2) {
		errors.firstname = 'Au moins 2 caractères';
	}
	// lastname
	if (!values.lastname) {
		errors.lastname = 'Nécessaire';
	} else if (values.lastname.length < 2) {
		errors.lastname = 'Au moins 2 caractères';
	}
	// phone
	if (!values.phone) {
		errors.phone = 'Nécessaire';
	} else if (!(/^07[0-9][0-9]{3}[0-9]{4}$/).test(values.phone)) {
		errors.phone = 'Le téléphone doit être un mobile au format 07XXXXXXXX';
	}
	// birth
	if (!values.birth) {
		errors.birth = 'Nécessaire';
	} else if (!(/[1,2][9,0][0-9][0-9]-[0-1][0-9]-[0-3][0-9]/).test(values.birth)) {
		errors.birth = 'Le format doit être AAAA-MM-JJ (1957-05-20)';
	} else if (calculateAge(new Date(values.birth)) < 18) {
		errors.birth = 'Vous devez être majeur (>18 ans)';
	} else if (calculateAge(new Date(moment(values.birth).format('YYYY-MM-DD'))) < 18) {
		errors.birth = 'Vous devez être majeur (>18 ans)';
	}

	// email
	if (!values.email) {
		errors.email = 'Nécessaire';
	} else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(values.email)) {
		errors.email = 'Email non valable';
	}
	// password
	if (!values.password) {
		errors.password = 'Nécessaire';
	} else if ((/ /i).test(values.userID)) {
		errors.userID = 'Pas d\'espace';
	} else if (values.password.length < 6) {
		errors.password = 'Minimum 6 caractères';
	}
	// passwordRepeat
	if (!values.passwordRepeat) {
		errors.passwordRepeat = 'Nécessaire';
	} else if (values.password !== values.passwordRepeat) {
		errors.passwordRepeat = 'Mots de passe différents!';
	}
	// street
	if (!values.street) {
		errors.street = 'Nécessaire';
	} else if (values.street.length < 2) {
		errors.street = 'Au moins 2 caractères';
	}
	// number
	if (!values.number) {
		errors.number = 'Nécessaire';
	} else if (values.number.length < 1) {
		errors.number = 'Au moins 1 caractères';
	}
	// npa
	if (!values.npa) {
		errors.npa = 'Nécessaire';
	} else if (values.npa.length < 4) {
		errors.npa = 'Au moins 4 caractères';
	} else if (values.npa.length > 5) {
		errors.npa = 'Au maximum 5 caractères';
	}
	// city
	if (!values.city) {
		errors.city = 'Nécessaire';
	} else if (values.city.length < 2) {
		errors.city = 'Au moins 2 caractères';
	}
	return errors;
};

let Page_register_to_trakyv = (props) => {
	const {ui, handleSubmit, submitting, navigator, authenticated, account, actions} = props;
	return (
		<Page
			renderToolbar={() => <NavBar title='Enregistrement' navigator={navigator} backButton={true} right={
			<div>
			{
				authenticated ?
				<div >
					<SignOutTBButton key='b1' authenticated={authenticated} actions={actions} navigator={navigator}/>
				</div> :
				null
			}
			</div>
		}/>}
			renderModal={() => ( // Modal for account in creation (waiting for server answer)
				<Modal
					isOpen={account.isFetching || account.isInvalid}
				>
					<div style={{display: 'flex', justifyContent: 'center'}}>
					{
						account.isFetching &&
						<section style={{width: '80%'}}>
							<p>
								Enregistrement en cours, merci de patienter...
							</p>
						</section>
					}
					{
						account.isInvalid &&
						<section style={{width: '80%'}}>
							<p>
								Problème réseau ou serveur. <br/>
								Merci d'essayer à nouveau. <br/>
							</p>
							<p>
								Erreur reçue: {account.error}
							</p>
							<Button
								navigator={navigator}
								onClick={() => {
									actions.receiveCreateTKAccount();
									actions.setErrorMessageCreateTKAccount('');
								}}
							>
								Retour
							</Button>
						</section>
					}
					</div>
				</Modal>)
			}
		>
			<List modifier='inset' style={{marginTop: '8px'}}>
				<Field name='firstname' component={renderField} type='text' placeholder='Prénom'/>
				<Field name='lastname' component={renderField} type='text' placeholder='Nom'/>
				<Field name='email' component={renderField} type='text' placeholder='E-mail' normalize={lower}/>
				<Field name='phone' component={renderField} type='text' placeholder='Téléphone mobile' normalize={normalizePhone}/>
				<Field name='birth' component={renderField} type='date' placeholder='Date de naissance' actions={actions} ui={ui}/>
				<Field name='password' component={renderField} type='password' placeholder='Mot de passe'/>
				<Field name='passwordRepeat' component={renderField} type='password' placeholder='Confirmer le mot de passe'/>
				<Field name='street' component={renderField} type='text' placeholder='Rue'/>
				<Field name='number' component={renderField} type='text' placeholder='Numéro'/>
				<Field name='npa' component={renderField} type='text' placeholder='NPA'/>
				<Field name='city' component={renderField} type='text' placeholder='Ville'/>
				<ListItem>
					<div className='center'>
						<div className='list__item__title'>
							Pays
						</div>
						<div className='list__item__subtitle'>
							<Field name='country' component='select'>
								<option value='CH'>Suisse</option>
								<option value='FR'>France</option>
							</Field>
						</div>
					</div>
				</ListItem>
				<ListItem>
					<Button type='submit' modifier='large'
						navigator={navigator}
						onClick={handleSubmit(handleFormSubmit(props))}
						disabled={submitting}
					>
						S'enregister
					</Button>
				</ListItem>
			</List>
			<TrakyvFooter />
		</Page>
	);
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
Page_register_to_trakyv = reduxForm({
	form: 'register', // a unique identifier for this form
	validate
})(Page_register_to_trakyv);

const hoc = compose(
	connect(
		(state, props) => ({
			authenticated: state.auth.authenticated,
			account: state.account,
			ui: state.ui,
			initialValues: {country: 'CH'}
		}),
		(dispatch) => ({
			actions: bindActionCreators(Actions, dispatch)
		})
	)
);

export default hoc(Page_register_to_trakyv);
