import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../actions';
import { Field, reduxForm } from 'redux-form';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';

import ons from 'onsenui';
import { Button, List, ListItem, ListHeader, Modal, Switch, Page } from 'react-onsenui';
import { renderField, styles, TrakyvFooter } from '../helpers';

import NavBar from './NavBar';

import { PAGE_VELO_EDITOR, TEXT_HTML_GENERAL_CONDITIONS, STATUS_CODE_PRE_ACTIVATION, TRAKYV_STATE_PREACTIVATED } from '../constants';

import Page_velo_editor from './Page_velo_editor';

const handleFormSubmit = (props) => ({street, building, npa, city, country, findingDate, findingHour, thief, clues, witnesses}) => {
	const {actions, velo, switchState, navigator} = props;
	let _thief, _clues, _witnesses, _country;
	if (switchState.thiefState) {
		_thief = thief || 'oui';
	} else {
		_thief = 'non';
	}
	if (switchState.cluesState) {
		_clues = clues || 'oui';
	} else {
		_clues = 'non';
	}
	if (switchState.witnessesState) {
		_witnesses = witnesses || 'oui';
	} else {
		_witnesses = 'non';
	}
	if (!country) {
		_country = 'CH';
	} else {
		_country = country;
	}
	ons.notification.confirm({
		title: ['Conditions d\'utilisation'],
		messageHTML: TEXT_HTML_GENERAL_CONDITIONS,
		buttonLabels: ['Non', 'J\'accepte']
	})
	.then((choice) => {
		switch (choice) {
			case 1:
				// Now submit the data
				return actions.fetchUpdateTKVeloState(JSON.stringify({deviceID: velo.deviceID, statusCode: STATUS_CODE_PRE_ACTIVATION, street, building, npa, city, findingDate: Math.floor((new Date(`${findingDate}T${findingHour}`)).getTime() / 1000), thief: _thief, clues: _clues, witnesses: _witnesses, country: _country}))
					.then((response) => {
						actions.updateVelo({isLost: TRAKYV_STATE_PREACTIVATED});
						actions.setListVeloAchievedRequest(false);
						navigator.popPage();
					});
			default:
				return null;
		}
	});
};

const validate = (values) => {
	const errors = {};
	// street
	if (!values.street) {
		errors.street = 'Nécessaire';
	} else if (values.street.length < 2) {
		errors.street = 'Au moins 2 caractères';
	}
	// building
	if (!values.building) {
		errors.building = 'Nécessaire';
	} else if (values.building.length < 1) {
		errors.building = 'Au moins 1 caractères';
	}
	// npa
	if (!values.npa) {
		errors.npa = 'Nécessaire';
	} else if (values.npa.length < 4) {
		errors.npa = 'Au moins 4 caractères';
	} else if (values.npa.length > 5) {
		errors.npa = 'Au maximum 5 caractères';
	}
	// city
	if (!values.city) {
		errors.city = 'Nécessaire';
	} else if (values.city.length < 2) {
		errors.city = 'Au moins 2 caractères';
	}
	// findingDate
	if (!values.findingDate) {
		errors.findingDate = 'Nécessaire';
	} else if (!(/[1,2][9,0][0-9][0-9]-[0-1][0-9]-[0-3][0-9]/).test(values.findingDate)) {
		errors.findingDate = 'Le format doit être AAAA-MM-JJ (1957-05-20)';
	}
	// findingHour
	if (!values.findingHour) {
		errors.findingHour = 'Nécessaire';
	} else if (!(/[0-2][0-9]:[0-5][0-9]/).test(values.findingHour)) {
		errors.findingHour = 'Le format doit être HH:MM (20:11)';
	}
	// // thief
	// if (!values.thief) {
	// 	errors.thief = 'Nécessaire';
	// } else if (values.thief.length < 3) {
	// 	errors.thief = 'Répondez au moins "oui" ou "non"';
	// }
	// // clues
	// if (!values.clues) {
	// 	errors.clues = 'Nécessaire';
	// } else if (values.clues.length < 3) {
	// 	errors.clues = 'Répondez au moins "oui" ou "non"';
	// }
	// // witnesses
	// if (!values.witnesses) {
	// 	errors.witnesses = 'Nécessaire';
	// } else if (values.witnesses.length < 3) {
	// 	errors.witnesses = 'Répondez au moins "oui" ou "non"';
	// }
	console.log('validation errrors', errors);
	return errors;
};

let Page_velo_declaration_vol = (props) => {
	const {handleSubmit, submitting, valid, navigator, listVelo, switchState, handleChange, actions} = props;
	return (
		<Page
			renderToolbar={() => <NavBar title='Déclaration de vol' navigator={navigator} backButton={true} />}
			renderModal={() => ( // Modal for account in creation (waiting for server answer)
				<Modal
					isOpen={listVelo.isFetching || listVelo.isInvalid}
				>
					<div style={{display: 'flex', justifyContent: 'center'}}>
					{
						listVelo.isFetching &&
						<section style={{width: '80%'}}>
							<p>
								Vérification et enregistrement en cours, merci de patienter...
							</p>
						</section>
					}
					{
						listVelo.isInvalid &&
						<section style={{width: '80%'}}>
							<p>
								Problème réseau ou dans les données entrées. <br/>
								Veuillez essayer à nouveau.
							</p>
							<p>
								Erreur reçue: {listVelo.error}
							</p>
							<Button
								navigator={navigator}
								onClick={() => {
									actions.receiveTKCreateVelo();
								}}
							>
								Retour
							</Button>
						</section>
					}
					</div>
				</Modal>)
			}
		>
			<div key='1' style={styles.titreDeclaration}>
				Attention, vous êtes sur le point de déclencher la recherche de votre vélo par le réseau Trakyv.<br></br><br></br><b>Cette action n'est à effectuer qu'en cas de vol.<p><u>La police pourra être alertée.</u></p></b>
			</div>
			<div style={styles.titreFormulaire}>
				Les informations relatives à votre vélo ainsi que vos informations personnelles doivent être complètes et correctes, assurez-vous qu'elles le sont en vous rendant sur <a href="#" onClick={(e) => {
					e.preventDefault();
					navigator.pushPage({component: Page_velo_editor, key: PAGE_VELO_EDITOR});
				}}> votre passeport vélo</a>
			</div>
			<div style={styles.titreFormulaire}>
				<b>Complétez le formulaire de déclaration de vol:</b>
			</div>
			<List modifier='inset' style={{marginTop: '8px'}}
				renderHeader={() => <ListHeader style={{marginTop: '-8px'}}>
					1. Où avez-vous vu votre vélo pour la dernière fois?
				</ListHeader>}
				>
				<Field name='street' component={renderField} type='text' placeholder='Rue'/>
				<Field name='building' component={renderField} type='number' placeholder='Numéro' />
				<Field name='npa' component={renderField} type='number' placeholder='Numéro postal'/>
				<Field name='city' component={renderField} type='text' placeholder='Ville'/>
				<ListItem>
					<div className='center'>
						<div className='list__item__title'>
							Pays
						</div>
						<div className='list__item__subtitle'>
							<Field name='country' component='select'>
								<option value='CH'>Suisse</option>
								<option value='FR'>France</option>
							</Field>
						</div>
					</div>
				</ListItem>
			</List>
			<List modifier='inset' style={{marginTop: '8px'}}
				renderHeader={() => <ListHeader style={{margin: 'auto'}}>
					2. À quelle date avez-vous constaté le délit?
				</ListHeader>}
				>
				<Field name='findingDate' component={renderField} type='date' placeholder='Date du constat'/>
				<Field name='findingHour' component={renderField} type='time' placeholder='Heure du constat'/>
			</List>
			<List modifier='inset' style={{marginTop: '8px'}}
				renderHeader={() => <ListHeader style={{margin: 'auto'}}>
					3. Eléments supplémentaires
				</ListHeader>}
				>
				<ListItem>
					<div className='center'>
						Avez-vous vu le voleur et pouvez-vous donner son signalement?
					</div>
					<div className='right'>
						<Switch name='thiefState' checked={switchState.thiefState} onChange={(e) => handleChange(e, 'thiefState')}>
						</Switch>
					</div>
				</ListItem>
				{
					switchState.thiefState &&
					<Field name='thief' component={renderField} type='text' placeholder='Signalement du voleur'/>
				}
				<ListItem>
					<div className='center'>
						Y-a-t'il des indices qui puissent aider la police?
					</div>
					<div className='right'>
						<Switch name='cluesState' checked={switchState.cluesState} onChange={(e) => handleChange(e, 'cluesState')}>
						</Switch>
					</div>
				</ListItem>
				{
					switchState.cluesState &&
					<Field name='clues' component={renderField} type='text' placeholder='Indices éventuels'/>
				}
				<ListItem>
					<div className='center'>
						Y-a-t'il des témoins?
					</div>
					<div className='right'>
						<Switch name='witnessesState' checked={switchState.witnessesState} onChange={(e) => handleChange(e, 'witnessesState')}>
						</Switch>
					</div>
				</ListItem>
				{
					switchState.witnessesState &&
					<Field name='witnesses' component={renderField} type='text' placeholder='Témoins éventuels'/>
				}
			</List>
			<List modifier='inset' style={{marginTop: '8px'}}>
				<ListItem>
					<Button modifier='large' disabled={submitting || !valid} onClick={handleSubmit(handleFormSubmit(props))}
					>
						Valider la pré-activation de la recherche
					</Button>
				</ListItem>
			</List>
			<TrakyvFooter />
		</Page>
	);
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
Page_velo_declaration_vol = reduxForm({
	form: 'vol_editor', // a unique identifier for this form
	validate
})(Page_velo_declaration_vol);

const hoc = compose(
	connect(
		(state, props) => ({
			listVelo: state.listVelo,
			velo: state.listVelo[state.selections.selectedDeviceID],
			initialValues: {country: 'CH'}
		}),
		(dispatch) => ({
			actions: bindActionCreators(Actions, dispatch)
		})
	),
	withState(
		'switchState',
		'setSwitchState',
		{
			thiefState: false, cluesState: false, witnessesState: false
		}
	),
	withHandlers({
		handleChange: ({ switchState, setSwitchState }) => (e, name) => {
			setSwitchState({...switchState, [name]: e.target.checked});
		}
	})
);

export default hoc(Page_velo_declaration_vol);
