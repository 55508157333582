import React from 'react';

import {notification} from 'onsenui';
import {Icon, ToolbarButton} from 'react-onsenui';

import {
  PAGE_SIGNIN
} from '../constants';

import SignIn from '../components/auth/SignIn';

const SignOutTBButton = ({navigator, authenticated, actions}) => (
	authenticated ?
	<ToolbarButton
		onClick={() => {
			notification.confirm({
				title: 'Alerte',
				message: 'Voulez-vous vraiment quitter Trakyv?',
				buttonLabels: ['Non', 'Oui']
			})
			.then((response) => {
				switch (response) {
					case 1:
						actions.signOutUser({navigator});
						navigator.resetPageStack([{component: SignIn, key: PAGE_SIGNIN}]);
						break;
					default:
					break;
				}
			});
		}}>
		<Icon icon='fa-sign-out'/>
	</ToolbarButton> :
	null
);

export default SignOutTBButton;
