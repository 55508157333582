import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../actions';
import { Field, reduxForm } from 'redux-form';
import compose from 'recompose/compose';

import {List, Input, Button, ListItem, ListHeader, Modal, Page} from 'react-onsenui';

import NavBar from './NavBar';
import SignOutTBButton from './SignOutTBButton';

import { PAGE_SIGNIN } from '../constants';
import { renderField, TrakyvFooter } from '../helpers';

import SignIn from '../components/auth/SignIn';

const handleFormSubmit = (props) => ({password}) => {
	const {actions, navigator} = props;

	return actions.fetchSetTKNewPassword({password, token: navigator.props.initialRoute.token})
	.then(() => {
		actions.setResetTKPasswordSuccess();
	})
	.catch(error => {
		console.log('Error while requesting pw change', error);
	});
};

const validate = (values) => {
	const errors = {};
	// password
	if (!values.password) {
		errors.password = 'Nécessaire';
	} else if ((/ /i).test(values.userID)) {
		errors.userID = 'Pas d\'espace';
	} else if (values.password.length < 6) {
		errors.password = 'Minimum 6 caractères';
	}
	// passwordRepeat
	if (!values.passwordRepeat) {
		errors.passwordRepeat = 'Nécessaire';
	} else if (values.password !== values.passwordRepeat) {
		errors.passwordRepeat = 'Mots de passe différents!';
	}
	return errors;
};

let Page_set_new_password = (props) => {
	const {handleSubmit, submitting, navigator, authenticated, account, actions} = props;
	return (
		<Page
			renderToolbar={() => <NavBar title='Nouveau mot de passe' navigator={navigator} backButton={false} right={
			<div>
			{
				authenticated ?
				<div >
					<SignOutTBButton key='b1' authenticated={authenticated} actions={actions} navigator={navigator}/>
				</div> :
				null
			}
			</div>}/>}
			renderModal={() => ( // Modal for account in creation (waiting for server answer)
				<Modal
					isOpen={account.isFetching || account.isInvalid || account.newPWRequested}
				>
					<div style={{display: 'flex', justifyContent: 'center'}}>
					{
						account.isFetching &&
						<section style={{margin: '16px'}}>
							<p style={{opacity: 0.6}}>
								Demande en cours, merci de patienter.
							</p>
						</section>
					}
					{
						account.newPWRequested &&
						<section style={{margin: '16px'}}>
							<p style={{opacity: 0.6}}>
								Demande aboutie, vérifier votre boîte email pour trouver la confirmation.
							</p>
							<Button
								navigator={navigator}
								onClick={() => {
									actions.clearResetTKPasswordSuccess();
									document.location.replace('https://app.trakyv.com');
									//navigator.resetPageStack([{component: SignIn, key: PAGE_SIGNIN}]);
								}}
							>
								Login
							</Button>
						</section>
					}
					{
						account.isInvalid &&
						<section style={{margin: '16px'}}>
							<p style={{opacity: 0.6}}>
								Vérifiez vos informations et essayer à nouveau
							</p>
							<p>
								Erreur reçue: {account.error}
							</p>
							<Button
								navigator={navigator}
								onClick={() => {
									actions.receiveResetTKPassword();
								}}
							>
								Retour
							</Button>
						</section>
					}
					</div>
				</Modal>)
			}
		>
			<List modifier='inset' style={{marginTop: '8px'}}
				renderHeader={() => <ListHeader style={{marginTop: '-8px'}}>
					Entrez votre nouveau mot de passe
				</ListHeader>}
			>
				<Field name='password' component={renderField} type='password' placeholder='Mot de passe'/>
				<Field name='passwordRepeat' component={renderField} type='password' placeholder='Confirmer le mot de passe'/>
				<ListItem>
					<Button type='submit' modifier='large'
						navigator={navigator}
						onClick={handleSubmit(handleFormSubmit(props))}
						disabled={submitting}
					>
						Valider
					</Button>
				</ListItem>
			</List>
			<TrakyvFooter />
		</Page>
	);
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
Page_set_new_password = reduxForm({
	form: 'setNewPW', // a unique identifier for this form
  validate
})(Page_set_new_password);

const hoc = compose(
	connect(
		(state, props) => ({
			authenticated: state.auth.authenticated,
			account: state.account
		}),
		(dispatch) => ({
			actions: bindActionCreators(Actions, dispatch)
		})
	)
);

export default hoc(Page_set_new_password);
