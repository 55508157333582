import axios from 'axios';
axios.defaults.headers.post['Content-Type'] = 'application/json';

const API_PROD_URL = 'https://api.chisfleet.com';
const API_TEST_URL = 'http://api.chisfleet.com:8084';
const ITADMIN_EMAIL = 'itadmin@chisfleet.com';

const API_URL = API_PROD_URL;

const getToken = () => {
	const cfpermission = JSON.parse(localStorage.getItem('cfpermission'));
	if (!cfpermission) {
		return Promise.reject('Bad or no token!'); // We need to leave the page and navigate to signin page!
	}
	const { token } = cfpermission;
	return {headers: { 'X-Auth-Token': token }};
};

const apiGetCall = (url) => {
	const params = getToken();
	return axios.get(url, params)
	.then(response => {
		if (response.status >= 400) {
			return Promise.reject(response.status);
		}
		return response;
	});
};

const apiPostCall = (url, rawData) => {
	if ((/signin/i).test(url)) {
		const headers = JSON.parse(rawData);
		return axios.post(url, null, {headers})
		.then(response => {
			if (response.status >= 400) {
				return Promise.reject(response.status);
			}
			return response;
		});
	} else {
		const params = getToken();
		return axios.post(url, rawData, params)
		.then(response => {
			if (response.status >= 400) {
				return Promise.reject(response.status);
			}
			return response;
		});
	}
};

const apiDeleteCall = (url) => {
	const params = getToken();
	return axios.delete(url, params)
		.then(response => {
			if (response.status >= 400) {
				return Promise.reject(response.status);
			}
			return response;
		});
};

// AUTH
export const apiTKSignIn = ({email, password}) => {
	return apiPostCall(`${API_URL}/signin`, JSON.stringify({
		'X-Auth-Email': email,
		'X-Auth-Password': password}))
	.then((response) => {
		return response.data;
	});
};

// ACCOUNT
export const apiTKCreateAccount = (rawJSON) => {
	return apiPostCall(`${API_URL}/trakyv/account/create`, rawJSON)
	.then((response) => {
		return response.data;
	});
};

export const apiTKUpdateAccount = (rawJSON) => {
	return apiPostCall(`${API_URL}/trakyv/account/update`, rawJSON)
	.then((response) => {
		return response.data;
	});
};

export const apiTKGetAccountProfile = () => {
	return apiGetCall(`${API_URL}/trakyv/account`)
	.then((response) => {
		return response.data;
	});
};

// VELO
export const apiTKCreateVelo = (rawJSON) => {
	return apiPostCall(`${API_URL}/trakyv/velo/create`, rawJSON)
	.then((response) => {
		return response.data;
	});
};

export const apiTKUpdateVelo = (rawJSON) => {
	return apiPostCall(`${API_URL}/trakyv/velo/update`, rawJSON)
	.then((response) => {
		return response.data;
	});
};

export const apiTKUpdateVeloState = (rawJSON) => {
	return apiPostCall(`${API_URL}/trakyv/velo/event`, rawJSON)
	.then((response) => {
		return response.data;
	});
};

export const apiTKGetVelo = () => {
	return apiGetCall(`${API_URL}/trakyv/velo/list`)
	.then((response) => {
		return response.data;
	});
};

export const apiTKResetPassword = (rawJSON) => {
	return apiPostCall(`${API_URL}/trakyv/account/password/reset`, rawJSON)
	.then((response) => {
		return response.data;
	});
};

export const apiTKSetNewPassword = ({password, token}) => {
	return axios.post(`${API_URL}/trakyv/account/password/set`, JSON.stringify({'password': password}), {headers: { 'X-Auth-Token': token }})
	.then(response => {
		if (response.status >= 400) {
			return Promise.reject(response.status);
		}
		return response;
	});
};

export const apiTKTransferVelo = deviceID => {
	return apiPostCall(`${API_URL}/trakyv/velo/transfer`, JSON.stringify({deviceID}))
	.then((response) => {
		return response.data;
	});
};
