import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../actions";
import { Field, reduxForm } from "redux-form";
import compose from "recompose/compose";
import withState from "recompose/withState";

import {
	List,
	Button,
	Carousel,
	CarouselItem,
	Icon,
	ListItem,
	ListHeader,
	Modal,
	Page,
} from "react-onsenui";
import { styles, renderField, TrakyvFooter } from "../helpers";
import { Image, CloudinaryContext, Transformation } from "cloudinary-react";
import ons from "onsenui";

import {
	COLOR_TRAKYV_BASE,
	STATUS_CODE_ACTIVATION,
	TRAKYV_STATE_ACTIVATED,
	DEFAULT_VELO_IMAGE,
} from "../constants";

import NavBar from "./NavBar";

class PlaintePicture extends Component {
	uploadWidget(_public_id) {
		const {
			velo: { plainteImage },
			actions,
			setCarouselIndex,
			formChange,
		} = this.props;
		const widget = window.cloudinary.openUploadWidget(
			{
				cloud_name: "trakyv-com",
				upload_preset: "vj8dmamk",
				theme: "minimal",
				multiple: false,
				tags: ["trakyv", "app", "dnsa"],
				sources: ["local"],
				cropping_default_selection_ratio: 1,
				text: {
					powered_by_cloudinary: "",
					"sources.local.title": "Mes fichiers",
					"sources.local.drop_file": "Drop file here",
					"sources.local.drop_files": "Drop files here",
					"sources.local.drop_or": "Or",
					"sources.local.select_file": "Choisir",
					"sources.local.select_files": "Choisir",
					"sources.url.title": "Web Address",
					"sources.url.note": "Public URL of an image file:",
					"sources.url.upload": "Upload",
					"sources.url.error": "Please type a valid HTTP URL.",
					"sources.camera.title": "Camera",
					"sources.camera.note":
						"Make sure your browser allows camera capture, position yourself and click Capture:",
					"sources.camera.capture": "Capture",
					"progress.uploading": "Chargement...",
					"progress.upload_cropped": "Upload",
					"progress.processing": "Traitement...",
					"progress.retry_upload": "Try again",
					"progress.use_succeeded": "OK",
					"progress.failed_note": "Some of your images failed uploading.",
				},
			},
			function (error, result) {
				if (result.event === "success") {
					const _imagesStringArray =
						plainteImage.length > 0 ? plainteImage.split(",") : [];
					const _newImgArray = _imagesStringArray.filter(
						(e) => e !== _public_id
					);
					_newImgArray.push(result.info.public_id);
					// on doit mettre l'image à jour!!
					actions.updateVelo({ plainteImage: _newImgArray.join(",") });
					setCarouselIndex(_newImgArray.length - 1);
					actions.fetchUpdateTKVelo();
					formChange("plainteImage", _newImgArray.join(",")); // an error occurs!
					widget.close();
				}
			}
		);
	}

	render() {
		const { public_id } = this.props;
		return (
			<div
				onClick={() => {
					ons.notification
						.confirm({
							title: ["Opération"],
							messageHTML: "Voulez-vous changer l'image?",
							buttonLabels: ["Annuler", "Changer"], //, 'Voir']
						})
						.then((response) => {
							switch (response) {
								case 0: // Annuler
									return 0;
								case 1: // Changer
									this.uploadWidget(public_id);
									return 1;
								case 2:
									// on veut voir les image -> image viewer?
									return 1;
								default:
									return 0;
							}
						});
				}}
			>
				<CloudinaryContext cloudName="trakyv-com">
					<Image secure="true" publicId={public_id}>
						<Transformation
							height="250"
							gravity="auto"
							crop="fill"
							radius="5"
							border="1px_solid_white"
							fetch_format="png"
							default_image={DEFAULT_VELO_IMAGE}
						/>
					</Image>
				</CloudinaryContext>
				{false && (
					<Icon
						icon="ion-minus-circled"
						size={{ default: 40, material: 36 }}
						style={{ color: "red" }}
						onClick={() => {
							this.props.actions.updateVelo({ plainteImage: "" });
							this.props.actions.fetchUpdateTKVelo();
						}}
					/>
				)}
			</div>
		);
	}
}

class AddPlaintePicture extends Component {
	uploadWidget() {
		console.log("velo", this.props.velo);
		const {
			velo: { plainteImage = "" },
			formChange,
			setCarouselIndex,
			actions,
		} = this.props;
		const widget = window.cloudinary.openUploadWidget(
			{
				cloud_name: "trakyv-com",
				upload_preset: "vj8dmamk",
				theme: "minimal",
				multiple: false,
				tags: ["trakyv", "app", "dnsa"],
				sources: ["local"],
				cropping_default_selection_ratio: 1,
				text: {
					powered_by_cloudinary: "",
					"sources.local.title": "Mes fichiers",
					"sources.local.drop_file": "Drop file here",
					"sources.local.drop_files": "Drop files here",
					"sources.local.drop_or": "Or",
					"sources.local.select_file": "Choisir",
					"sources.local.select_files": "Choisir",
					"sources.url.title": "Web Address",
					"sources.url.note": "Public URL of an image file:",
					"sources.url.upload": "Upload",
					"sources.url.error": "Please type a valid HTTP URL.",
					"sources.camera.title": "Camera",
					"sources.camera.note":
						"Make sure your browser allows camera capture, position yourself and click Capture:",
					"sources.camera.capture": "Capture",
					"progress.uploading": "Chargement...",
					"progress.upload_cropped": "Upload",
					"progress.processing": "Traitement...",
					"progress.retry_upload": "Try again",
					"progress.use_succeeded": "OK",
					"progress.failed_note": "Some of your images failed uploading.",
				},
			},
			function (error, result) {
				if (result.event === "success") {
					const _newImagesStringArray =
						plainteImage.length > 0 ? plainteImage.split(",") : [];
					_newImagesStringArray.push(result.info.public_id);

					// on doit mettre l'image à jour!!
					actions.updateVelo({ plainteImage: _newImagesStringArray.join(",") });
					actions.fetchUpdateTKVelo();
					setCarouselIndex(_newImagesStringArray.length - 1);
					formChange("plainteImage", _newImagesStringArray.join(",")); // an error occurs!
					widget.close();
				}
			}
		);
	}

	render() {
		//const {velo: {plainteImage = ''}} = this.props;
		//const _imgArray = plainteImage.length > 0 ? plainteImage.split(',') : [];
		return (
			<div>
				<div
					onClick={() => {
						ons.notification
							.confirm({
								title: ["Opération"],
								messageHTML: "Voulez-vous ajouter une photo de votre plainte?",
								buttonLabels: ["Non", "Oui"],
							})
							.then((response) => {
								switch (response) {
									case 1:
										this.uploadWidget();
										return 1;
									case 2:
										// on veut voir les image -> image viewer?
										return 1;
									default:
										return 0;
								}
							});
					}}
				>
					<Icon
						icon="ion-plus-circled"
						size={{ default: 40, material: 36 }}
						style={{ color: COLOR_TRAKYV_BASE }}
					/>
				</div>
				{false && (
					<Icon
						icon="ion-minus-circled"
						size={{ default: 40, material: 36 }}
						style={{ color: "red" }}
						onClick={() => {
							this.props.actions.updateVelo({ plainteImage: "" });
							this.props.actions.fetchUpdateTKVelo();
						}}
					/>
				)}
			</div>
		);
	}
}

const handleFormSubmit =
	(props) =>
	({ plainteID }) => {
		const { actions, velo, navigator } = props;

		return actions
			.fetchUpdateTKVeloState(
				JSON.stringify({
					deviceID: velo.deviceID,
					statusCode: STATUS_CODE_ACTIVATION,
					plainteID,
				})
			)
			.then((response) => {
				actions.updateVelo({ isLost: TRAKYV_STATE_ACTIVATED });
				navigator.popPage();
			});
	};

const validate = (values) => {
	const errors = {};
	// Image of plainte
	if (values.plainteImage === "") {
		errors.plainteImage = "Au moins une photo de votre plainte est nécessaire";
	}
	// plainteID
	if (!values.plainteID) {
		errors.plainteID = "Nécessaire";
	} else if (values.plainteID.length < 6) {
		errors.plainteID = "Minimum 6 caractères";
	}
	// plainteIDRepeat
	if (!values.plainteIDRepeat) {
		errors.plainteIDRepeat = "Nécessaire";
	} else if (values.plainteID !== values.plainteIDRepeat) {
		errors.plainteIDRepeat = "Les N° de plainte diffèrent!";
	}
	return errors;
};

const renderImgField = ({
	input,
	type,
	meta: { touched, error },
	...custom
}) => (
	<ListItem>
		{error && (
			<div
				style={{
					color: "red",
					fontSize: "0.75em",
					fontWeight: "bold",
					textAlign: "left",
				}}
			>
				{error}
			</div>
		)}
	</ListItem>
);

let Page_velo_plainte_id = (props) => {
	const {
		handleSubmit,
		submitting,
		navigator,
		carouselIndex,
		listVelo,
		velo,
		actions,
	} = props;
	const _veloPlainteImages = velo.plainteImage
		? velo.plainteImage.split(",")
		: [];
	return (
		<Page
			renderToolbar={() => (
				<NavBar
					title="Dépôt de plainte électronique"
					navigator={navigator}
					backButton={true}
				/>
			)}
			renderModal={() => (
				// Modal for listVelo in creation (waiting for server answer)
				<Modal
					isOpen={
						listVelo.isFetching ||
						listVelo.isInvalid ||
						listVelo.requestAchieved
					}
				>
					<div style={{ display: "flex", justifyContent: "center" }}>
						{listVelo.isFetching && (
							<section style={{ margin: "16px" }}>
								<p style={{ opacity: 0.6 }}>
									Demande en cours, merci de patienter.
								</p>
							</section>
						)}
						{listVelo.requestAchieved && (
							<section style={{ margin: "16px" }}>
								<p style={{ opacity: 0.6 }}>
									Demande aboutie, vérifier votre boîte email pour trouver la
									confirmation.
								</p>
								<Button
									navigator={navigator}
									onClick={() => {
										actions.setListVeloAchievedRequest(false);
										navigator.popPage();
									}}
								>
									Retour
								</Button>
							</section>
						)}
						{listVelo.isInvalid && (
							<section style={{ margin: "16px" }}>
								<p style={{ opacity: 0.6 }}>
									Problème réseau, merci d'essayez à nouveau. Assurez-vous
									d'être connecté à internet.
								</p>
								<Button
									navigator={navigator}
									onClick={() => {
										actions.receiveTKCreateVelo();
									}}
								>
									Retour
								</Button>
							</section>
						)}
					</div>
				</Modal>
			)}
		>
			<div
				style={{
					width: "100%",
					marginTop: "10px",
					height: "280px",
					display: "flex",
					flexDirection: "row",
				}}
			>
				<div
					style={{
						width: "50px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				></div>
				<Carousel
					style={{ height: "280px" }}
					onPostChange={(e) => console.log("Carousel changed", e)}
					index={carouselIndex}
					swipeable
					autoScroll
					overscrollable
				>
					{_veloPlainteImages.length > 0 ? (
						_veloPlainteImages.map((item, index) => (
							<CarouselItem
								key={index}
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<PlaintePicture
									imgFieldName="plainteImage"
									public_id={item}
									velo={velo}
									actions={actions}
									formChange={props.change}
									setCarouselIndex={props.setCarouselIndex}
									{...props}
								/>
							</CarouselItem>
						))
					) : (
						<CarouselItem
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<AddPlaintePicture
								imgFieldName="plainteImage"
								velo={velo}
								actions={actions}
								formChange={props.change}
								setCarouselIndex={props.setCarouselIndex}
								{...props}
							/>{" "}
							<span style={{ marginLeft: "5px", fontSize: "0.75em" }}>
								Ajouter une photo de votre plainte
							</span>
						</CarouselItem>
					)}
				</Carousel>
				<div
					style={{
						width: "50px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{_veloPlainteImages.length > 0 && _veloPlainteImages.length < 3 ? (
						<AddPlaintePicture
							velo={velo}
							actions={actions}
							formChange={props.change}
							setCarouselIndex={props.setCarouselIndex}
							{...props}
						/>
					) : null}
				</div>
			</div>
			<List
				modifier="inset"
				style={{ marginTop: "8px" }}
				renderHeader={() => (
					<ListHeader style={{ marginTop: "-8px" }}>
						Renseigner le numéro de plainte reçu lors de votre dépôt
					</ListHeader>
				)}
			>
				<Field name="plainteImage" component={renderImgField} />
				<Field
					name="plainteID"
					component={renderField}
					type="text"
					placeholder="N° de plainte"
				/>
				<Field
					name="plainteIDRepeat"
					component={renderField}
					type="text"
					placeholder="Confirmer le N° de plainte"
				/>
				<ListItem>
					<Button
						type="submit"
						modifier="large"
						navigator={navigator}
						onClick={handleSubmit(handleFormSubmit(props))}
						disabled={submitting}
					>
						Valider et finaliser l'activation
					</Button>
				</ListItem>
			</List>
			<TrakyvFooter />
		</Page>
	);
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
Page_velo_plainte_id = reduxForm({
	form: "setPlainteID", // a unique identifier for this form
	validate,
})(Page_velo_plainte_id);

const hoc = compose(
	connect(
		(state, props) => ({
			listVelo: state.listVelo,
			velo: state.listVelo[state.selections.selectedDeviceID],
			initialValues: state.listVelo[state.selections.selectedDeviceID],
		}),
		(dispatch) => ({
			actions: bindActionCreators(Actions, dispatch),
		})
	),
	withState("carouselIndex", "setCarouselIndex", 0)
);

export default hoc(Page_velo_plainte_id);
