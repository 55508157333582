import React, { Component } from "react";
import { Image, CloudinaryContext, Transformation } from "cloudinary-react";
import ons from "onsenui";

import { DEFAULT_VELO_IMAGE, DEFAULT_INVOICE_IMAGE } from "../constants";

class TKPicture extends Component {
	uploadWidget() {
		const { imgFieldName, actions } = this.props;
		const widget = window.cloudinary.openUploadWidget(
			{
				cloud_name: "trakyv-com",
				upload_preset: "vj8dmamk",
				theme: "minimal",
				multiple: false,
				tags: ["trakyv", "app", "dnsa"],
				sources: ["local"],
				cropping_default_selection_ratio: 1,
				text: {
					powered_by_cloudinary: "",
					"sources.local.title": "Mes fichiers",
					"sources.local.drop_file": "Drop file here",
					"sources.local.drop_files": "Drop files here",
					"sources.local.drop_or": "Or",
					"sources.local.select_file": "Choisir",
					"sources.local.select_files": "Choisir",
					"sources.url.title": "Web Address",
					"sources.url.note": "Public URL of an image file:",
					"sources.url.upload": "Upload",
					"sources.url.error": "Please type a valid HTTP URL.",
					"sources.camera.title": "Camera",
					"sources.camera.note":
						"Make sure your browser allows camera capture, position yourself and click Capture:",
					"sources.camera.capture": "Capture",
					"progress.uploading": "Chargement...",
					"progress.upload_cropped": "Upload",
					"progress.processing": "Traitement...",
					"progress.retry_upload": "Try again",
					"progress.use_succeeded": "OK",
					"progress.failed_note": "Some of your images failed uploading.",
				},
			},
			function (error, result) {
				// result.event "source-changed"
				// result.event "upload-added"
				if (result.event === "success") {
					//Step 2.4:  Call the .close() method in order to close the widget
					actions.updateVelo({ [imgFieldName]: result.info.public_id });
					actions.fetchUpdateTKVelo();
					widget.close();
				}
			}
		);
	}

	render() {
		const { imgFieldName, viewType, velo } = this.props;
		const public_id = velo[imgFieldName] || DEFAULT_VELO_IMAGE;
		return (
			<div
				onClick={() => {
					ons.notification
						.confirm({
							title: ["Alerte"],
							messageHTML: "Voulez-vous changer l'image?",
							buttonLabels: ["Non", "Oui"],
						})
						.then((response) => {
							switch (response) {
								case 1:
									this.uploadWidget();
									return 1;
								default:
									return 0;
							}
						});
				}}
			>
				<CloudinaryContext cloudName="trakyv-com">
					<Image secure="true" publicId={public_id}>
						{viewType === "circle" ? (
							<Transformation
								width="150"
								height="150"
								gravity="auto"
								crop="fill"
								radius="max"
								border="1px_solid_white"
								fetch_format="png"
								default_image={DEFAULT_VELO_IMAGE}
							/>
						) : viewType === "rect" ? (
							<Transformation
								width="105"
								height="150"
								gravity="auto"
								crop="fill"
								radius="2"
								border="1px_solid_white"
								fetch_format="png"
								default_image={DEFAULT_INVOICE_IMAGE}
							/>
						) : (
							<Transformation
								width="150"
								height="150"
								gravity="auto"
								crop="fill"
								radius="2"
								border="1px_solid_white"
								fetch_format="png"
								default_image={DEFAULT_VELO_IMAGE}
							/>
						)}
						{((velo && velo[imgFieldName] && velo[imgFieldName].length === 0) ||
							!velo[imgFieldName]) &&
						viewType === "circle" ? (
							<Transformation overlay="text:Arial_18_center:Photo%20vélo%0DCliquez ici!" />
						) : ((velo &&
								velo[imgFieldName] &&
								velo[imgFieldName].length === 0) ||
								!velo[imgFieldName]) &&
						  viewType === "rect" ? (
							<Transformation overlay="text:Arial_18_center:Facture%0DCliquez ici!" />
						) : (
							<Transformation />
						)}
					</Image>
				</CloudinaryContext>
			</div>
		);
	}
}

export default TKPicture;
