import {AUTH_USER, UNAUTH_USER, AUTH_ERROR, USER_ACL} from '../actions/types';

const INITIAL_STATE = { authenticated: false, error: '', user_acl: '', accountID: '' };

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case AUTH_USER: // User is succesfully authenticated
			return { ...state, authenticated: true };
		case UNAUTH_USER:
			return { ...state, authenticated: false };
		case AUTH_ERROR:
			return { ...state, error: action.payload };
		case USER_ACL:
			return { ...state, user_acl: action.payload };
		default:
			return state;
	}
}
