/* eslint-disable import/first */
// export * from './ui';

import {
	AUTH_USER, UNAUTH_USER, AUTH_ERROR,
	SELECT_FEATURE, SELECT_DEVICE_ID,
	REQUEST_CREATE_TRAKYV_ACCOUNT, SET_CREATE_TRAKYV_ACCOUNT_ERROR, RECEIVE_CREATE_TRAKYV_ACCOUNT, SET_ERROR_MESSAGE_CREATE_TRAKYV_ACCOUNT, ADD_CREATED_ACCOUNT, ADD_ROLES_FEATURES,	REQUEST_TRAKYV_ACCOUNT_PROFILE, SET_TRAKYV_ACCOUNT_PROFILE_ERROR, RECEIVE_TRAKYV_ACCOUNT_PROFILE, ADD_TRAKYV_ACCOUNT_PROFILE, UPDATE_TRAKYV_ACCOUNT_PROFILE, REQUEST_RESET_TRAKYV_PASSWORD, SET_RESET_TRAKYV_PASSWORD_ERROR, RECEIVE_RESET_TRAKYV_PASSWORD, SET_NEWPW_REQUEST, CLEAR_NEWPW_REQUEST,
	REQUEST_TRAKYV_LIST_VELO, SET_TRAKYV_LIST_VELO_ERROR, RECEIVE_TRAKYV_LIST_VELO, ADD_TRAKYV_LIST_VELO, REQUEST_TRAKYV_CREATE_VELO, SET_TRAKYV_CREATE_VELO_ERROR, RECEIVE_TRAKYV_CREATE_VELO, ADD_TRAKYV_CREATED_VELO, UPDATE_VELO, SET_ERROR_MESSAGE_LIST_VELO, SET_LIST_VELO_ACHIEVED_REQUEST
} from './types';

import {
	apiTKSignIn, apiTKResetPassword, apiTKSetNewPassword,
	apiTKCreateAccount, apiTKGetAccountProfile, apiTKUpdateAccount,
	apiTKGetVelo, apiTKCreateVelo, apiTKUpdateVelo, apiTKUpdateVeloState, apiTKTransferVelo } from '../api/';

import { PAGE_SIGNIN, PAGE_MAIN_FEATURES_MENU } from '../constants';

import MainPage from '../containers/MainPage';
import SignIn from '../components/auth/SignIn';

import omit from 'lodash/omit';

// AUTH
export const authUser = () => ({
	type: AUTH_USER
});

export const authError = error => ({
	type: AUTH_ERROR,
	payload: error
});
export const SET_APP_VERSION = 'SET_APP_VERSION';

export const setAppVersion = (version) => ({
	type: SET_APP_VERSION,
	payload: version,
});

export const initRegisteredAccount = ({token, roles, features}) => {
	return function (dispatch) {
		localStorage.setItem('cfpermission', JSON.stringify({token}));
		dispatch({
			type: ADD_ROLES_FEATURES,
			roles,
			features
		});
	};
};

export const signInUser = ({ email, password, navigator }) => {
	return function (dispatch) {
		apiTKSignIn({ email, password })
			.then((response) => {
				const { token } = response;
				dispatch(authUser());
				// 	save the Token
				localStorage.setItem('cfpermission', JSON.stringify({ token	}));
				// 	redirect to features
				navigator.resetPageStack([{ component: MainPage, key: PAGE_MAIN_FEATURES_MENU }]);
			})
			.catch((error) => {
				// Here we'll need an error message in state and show it!
				dispatch(authError(error.response.data.message));
				console.log('Bad login!');
			});
	};
};

/**
 * Sign out the user and remove the stored token
 * @return {function} Dispatch UNAUTH_USER to the reducers
 */
export const signOutUser = () => {
	localStorage.removeItem('cfpermission');
	return function (dispatch) {
		dispatch({
			type: UNAUTH_USER
		});
	};
};

// RESET PASSWORD

const requestResetTKPassword = () => ({
	type: REQUEST_RESET_TRAKYV_PASSWORD
});

export const setRequestResetTKPasswordError = (error) => ({
	type: SET_RESET_TRAKYV_PASSWORD_ERROR,
	error
});

export const receiveResetTKPassword = () => ({
	type: RECEIVE_RESET_TRAKYV_PASSWORD
});

export const setResetTKPasswordSuccess = () => ({
	type: SET_NEWPW_REQUEST
});

export const clearResetTKPasswordSuccess = () => ({
	type: CLEAR_NEWPW_REQUEST
});

export const fetchResetTKPassword = (rawJSON) => {
	return function (dispatch) {
		dispatch(requestResetTKPassword());
		return apiTKResetPassword(rawJSON)
		.then((response) => {
			dispatch(receiveResetTKPassword());
		})
		.catch((error) => {
			console.log('fetchResetTKPassword request error:', error);
			dispatch(setRequestResetTKPasswordError());
			return Promise.error(error);
		});
	};
};

export const fetchSetTKNewPassword = ({password, token}) => {
	return function (dispatch) {
		dispatch(requestResetTKPassword());
		return apiTKSetNewPassword({password, token})
		.then((response) => {
			dispatch(receiveResetTKPassword());
		})
		.catch((error) => {
			console.log('fetchResetTKPassword request error:', error);
			dispatch(setRequestResetTKPasswordError(error.response.data.message));
			return Promise.error(error);
		});
	};
};

// SELECTIONS
export const selectFeature = (selectedFeature) => ({
	type: SELECT_FEATURE,
	selectedFeature
});

export const selectDeviceID = deviceID => ({
	type: SELECT_DEVICE_ID,
	deviceID
});

// CREATE_TRAKYV_ACCOUNT
const requestCreateTKAccount = () => ({
	type: REQUEST_CREATE_TRAKYV_ACCOUNT
});

const setRequestCreateTKAccountError = () => ({
	type: SET_CREATE_TRAKYV_ACCOUNT_ERROR
});

export const receiveCreateTKAccount = () => ({
	type: RECEIVE_CREATE_TRAKYV_ACCOUNT
});

const setErrorMessageCreateTKAccount = error => ({
	type: SET_ERROR_MESSAGE_CREATE_TRAKYV_ACCOUNT,
	error
});

const addCreatedAccount = (data) => ({
	type: ADD_CREATED_ACCOUNT,
	payload: data
});

export const fetchCreateTKAccount = (rawJSON) => {
	return function (dispatch) {
		dispatch(requestCreateTKAccount());
		return apiTKCreateAccount(rawJSON)
		.then((response) => {
			dispatch(addCreatedAccount(response.data));
			dispatch(authUser());
			dispatch(receiveCreateTKAccount());
		})
		.catch((error) => {
			console.log('fetchCreateTKAccount request error:', error);
			dispatch(setErrorMessageCreateTKAccount(error.response.data.message));
			dispatch(setRequestCreateTKAccountError());
			return Promise.error(error);
		});
	};
};

// TRAKYV_ACCOUNT_PROFILE
const requestTKAccountProfile = () => ({
	type: REQUEST_TRAKYV_ACCOUNT_PROFILE
});

const setRequestTKAccountProfileError = () => ({
	type: SET_TRAKYV_ACCOUNT_PROFILE_ERROR
});

export const receiveTKAccountProfile = () => ({
	type: RECEIVE_TRAKYV_ACCOUNT_PROFILE
});

const addTKAccountProfile = (data) => ({
	type: ADD_TRAKYV_ACCOUNT_PROFILE,
	payload: data
});

export const updateTKAccountProfile = (data) => ({
	type: UPDATE_TRAKYV_ACCOUNT_PROFILE,
	payload: data
});

export const fetchTKAccountProfile = (navigator) => {
	return function (dispatch) {
		dispatch(requestTKAccountProfile());
		return apiTKGetAccountProfile()
		.then((response) => {
			dispatch(addTKAccountProfile(response.data));
			dispatch(receiveTKAccountProfile());
		})
		.catch((error) => {
			console.log('fetchTKAccountProfile request error:', error);
			dispatch(setRequestTKAccountProfileError());
			// Here we can signout and remove the token
			dispatch(signOutUser());
			navigator.resetPageStack([{component: SignIn, key: PAGE_SIGNIN}]);
		});
	};
};

export const fetchUpdateTKAccount = (rawJSON) => {
	return function (dispatch) {
		dispatch(requestTKAccountProfile());
		return apiTKUpdateAccount(rawJSON)
		.then((response) => {
			setTimeout(() => {
				return dispatch(receiveTKAccountProfile());
			}, 1000);
		})
		.catch((error) => {
			console.log('fetchUpdateTKAccount request error:', error);
			dispatch(setRequestTKAccountProfileError());
		});
	};
};

// LISTVELO
const requestTKListVelo = () => ({
	type: REQUEST_TRAKYV_LIST_VELO
});

const setRequestTKListVeloError = () => ({
	type: SET_TRAKYV_LIST_VELO_ERROR
});

const receiveTKListVelo = () => ({
	type: RECEIVE_TRAKYV_LIST_VELO
});

const addTKListVelo = (devices) => ({
	type: ADD_TRAKYV_LIST_VELO,
	devices
});

export const fetchTKListVelo = () => {
	return function (dispatch) {
		dispatch(requestTKListVelo());
		return apiTKGetVelo()
		.then((response) => {
			dispatch(addTKListVelo(response.data));
			dispatch(receiveTKListVelo());
		})
		.catch((error) => {
			console.log('fetchTKListVelo request error:', error);
			dispatch(setRequestTKListVeloError());
		});
	};
};

const requestTKCreateVelo = () => ({
	type: REQUEST_TRAKYV_CREATE_VELO
});

const setRequestTKCreateVeloError = () => ({
	type: SET_TRAKYV_CREATE_VELO_ERROR
});

export const receiveTKCreateVelo = () => ({
	type: RECEIVE_TRAKYV_CREATE_VELO
});

export const setErrorMessageTKListVelo = error => ({
	type: SET_ERROR_MESSAGE_LIST_VELO,
	error
});

export const setListVeloAchievedRequest = bool => ({
	type: SET_LIST_VELO_ACHIEVED_REQUEST,
	bool
});

const addTKCreatedVelo = (data) => ({
	type: ADD_TRAKYV_CREATED_VELO,
	payload: data
});

export const fetchTKCreateVelo = (rawJSON) => {
	return function (dispatch) {
		dispatch(requestTKCreateVelo());
		return apiTKCreateVelo(rawJSON)
		.then((response) => {
			dispatch(addTKCreatedVelo(response.data));
			dispatch(setListVeloAchievedRequest(true));
			dispatch(receiveTKCreateVelo());
		})
		.catch((error) => {
			console.log('fetchTKCreateVelo request error:', error);
			dispatch(setErrorMessageTKListVelo(error.response.data.message));
			dispatch(setRequestTKCreateVeloError());
			return Promise.error(error);
		});
	};
};

export const fetchUpdateTKVelo = (rawJSON) => {
	return function (dispatch, getState) {
		let toBeSendJSON;
		if (!rawJSON) {
			const selectedDeviceID = getState().selections.selectedDeviceID;
			toBeSendJSON = JSON.stringify(omit(getState().listVelo[selectedDeviceID], ['year']));
		} else {
			toBeSendJSON = rawJSON;
		}
		dispatch(requestTKCreateVelo());
		return apiTKUpdateVelo(toBeSendJSON)
		.then((response) => {
			setTimeout(() => {
				return dispatch(receiveTKCreateVelo());
			}, 1000);
		})
		.catch((error) => {
			console.log('fetchUpdateTKVelo request error:', error);
			dispatch(setErrorMessageTKListVelo(error.response.data.message));
			dispatch(setRequestTKCreateVeloError());
			return Promise.error(error);
		});
	};
};

export const fetchUpdateTKVeloState = (rawJSON) => {
	return function (dispatch, getState) {
		dispatch(requestTKCreateVelo());
		return apiTKUpdateVeloState(rawJSON)
		.then((response) => {
			dispatch(setListVeloAchievedRequest(true));
			return dispatch(receiveTKCreateVelo());
		})
		.catch((error) => {
			console.log('fetchUpdateTKVeloState request error:', error);
			dispatch(setRequestTKCreateVeloError());
			return Promise.error(error);
		});
	};
};

export const updateVelo = data => {
	return function (dispatch, getState) {
		const selectedDeviceID = getState().selections.selectedDeviceID;
		dispatch({
			type: UPDATE_VELO,
			selectedDeviceID,
			payload: data
		});
	};
};

export const fetchTransferTKVelo = deviceID => {
	return function (dispatch, getState) {
		dispatch(requestTKCreateVelo());
		return apiTKTransferVelo(deviceID)
		.then((response) => {
			dispatch(setListVeloAchievedRequest(true));
			dispatch(receiveTKCreateVelo());
			return response;
		})
		.catch((error) => {
			console.log('fetchTransferTKVelo request error:', error);
			dispatch(setRequestTKCreateVeloError());
			throw error;
		});
	};
}
