import React from 'react';

import {
	Toolbar,
	BackButton
} from 'react-onsenui';

const NavBar = ({title, navigator, backButton, right, backButtonOnClick}) => (
	<Toolbar>
		<div className='left'>
			{backButton ? <BackButton onClick={backButtonOnClick ? backButtonOnClick : () => navigator.popPage()}></BackButton> : null}
		</div>
		<div className='center'>{title}</div>
		<div className='right'>{right}</div>
	</Toolbar>
);

export default NavBar;
