import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../actions';
import { Field, reduxForm } from 'redux-form';
import compose from 'recompose/compose';

import { PAGE_REGISTER_TO_TRAKYV, PAGE_RESET_PASSWORD } from '../../constants';

import { Button, List, ListItem } from 'react-onsenui';
import { lower, renderField, styles, TrakyvFooter, TrakyvHeader } from '../../helpers';

import Page_register_to_trakyv from '../../containers/Page_register_to_trakyv';
import Page_reset_password from '../../containers/Page_reset_password';

const handleFormSubmit = (props) => ({email, password}) => {
	const {actions, navigator} = props;
	actions.signInUser({email, password, navigator});
};

const validate = (values) => {
	const errors = {};
	// accountID
	if (!values.email) {
		errors.email = 'Nécessaire';
	} else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(values.email)) {
		errors.email = 'Email non valable';
	}
	// password
	if (!values.password) {
		errors.password = 'Nécessaire';
	} else if ((/ /i).test(values.userID)) {
		errors.userID = 'Pas d\'espace';
	}
	return errors;
};

let SignIn = (props) => {
	const {auth, handleSubmit, submitting, navigator} = props;
	return (
		<div style={{backgroundColor: 'white'}}>
			<TrakyvHeader {...props}/>
			<List>
				<form style={{textAlign: 'left'}}>
					<ListItem>
						<div style={styles.profileId}>
							Identifiez-vous ou créez un compte
						</div>
					</ListItem>
					<Field name='email' component={renderField} type='text' placeholder='E-mail' normalize={lower}/>
					<Field name='password' component={renderField} type='password' placeholder='Mot de passe'/>
					<ListItem>
						<Button type='submit' modifier='large'
							navigator={navigator}
							onClick={handleSubmit(handleFormSubmit(props))}
							disabled={submitting}>
							Login
						</Button>
						<div style={{color: 'red', fontSize: '0.75em', fontWeight: 'bold', textAlign: 'left' }}>
							{auth && auth.error}
						</div>
					</ListItem>
				</form>
				<ListItem>
					<Button modifier='large' onClick={() => {
							navigator.pushPage({component: Page_reset_password, key: PAGE_RESET_PASSWORD});
					}}>Mot de passe oublié?</Button>
					<Button type='submit' modifier='quiet'
						style={{marginTop: 10}}
						navigator={navigator}
						onClick={() => {
							navigator.pushPage({component: Page_register_to_trakyv, key: PAGE_REGISTER_TO_TRAKYV});
						}}>
						Nouvel utilisateur? Créez un compte
					</Button>
				</ListItem>
			</List>
			<TrakyvFooter />
		</div>
	);
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
SignIn = reduxForm({
	form: 'signin', // a unique identifier for this form
  validate
})(SignIn);

const signInHOC = compose(
	connect(
		state => ({
			auth: state.auth,
			appVersion: "1.9.3"
		}),
		(dispatch) => ({
			actions: bindActionCreators(Actions, dispatch)
		})
	)
);

export default signInHOC(SignIn);
