import {
	REQUEST_CREATE_TRAKYV_ACCOUNT, SET_CREATE_TRAKYV_ACCOUNT_ERROR, RECEIVE_CREATE_TRAKYV_ACCOUNT, SET_ERROR_MESSAGE_CREATE_TRAKYV_ACCOUNT, ADD_CREATED_ACCOUNT,
	REQUEST_TRAKYV_ACCOUNT_PROFILE, SET_TRAKYV_ACCOUNT_PROFILE_ERROR, RECEIVE_TRAKYV_ACCOUNT_PROFILE, ADD_TRAKYV_ACCOUNT_PROFILE,
	UPDATE_TRAKYV_ACCOUNT_PROFILE,
	REQUEST_RESET_TRAKYV_PASSWORD, SET_RESET_TRAKYV_PASSWORD_ERROR, RECEIVE_RESET_TRAKYV_PASSWORD, SET_NEWPW_REQUEST, CLEAR_NEWPW_REQUEST
} from '../actions/types';

const INITIAL_STATE = {
	isFetching: false, isInvalid: false, error: '', newPWRequested: false
};

const account = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_NEWPW_REQUEST:
			return {
				...state, newPWRequested: true
			};
		case CLEAR_NEWPW_REQUEST:
			return {
				...state, newPWRequested: false
			};
		case SET_ERROR_MESSAGE_CREATE_TRAKYV_ACCOUNT:
			return {
				...state, error: action.error
			};
		case REQUEST_RESET_TRAKYV_PASSWORD:
		case REQUEST_TRAKYV_ACCOUNT_PROFILE:
		case REQUEST_CREATE_TRAKYV_ACCOUNT:
			return {
				...state, isFetching: true, isInvalid: false
			};
		case SET_RESET_TRAKYV_PASSWORD_ERROR:
			return {
				...state, isFetching: false, isInvalid: true, error: action.error ? action.error : ''
			};
		case SET_TRAKYV_ACCOUNT_PROFILE_ERROR:
		case SET_CREATE_TRAKYV_ACCOUNT_ERROR:
			return {
				...state, isFetching: false, isInvalid: true
			};
		case RECEIVE_RESET_TRAKYV_PASSWORD:
		case RECEIVE_TRAKYV_ACCOUNT_PROFILE:
		case RECEIVE_CREATE_TRAKYV_ACCOUNT:
			return {
				...state, isFetching: false, isInvalid: false, error: ''
			};
		case ADD_TRAKYV_ACCOUNT_PROFILE:
		case UPDATE_TRAKYV_ACCOUNT_PROFILE:
		case ADD_CREATED_ACCOUNT:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};

export default account;
