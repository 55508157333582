// AUTH
export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const USER_ACL = 'USER_ACL';
export const RESET_STATE = 'RESET_STATE';

// ACCOUNT
export const REQUEST_CREATE_TRAKYV_ACCOUNT = 'REQUEST_CREATE_TRAKYV_ACCOUNT';
export const SET_CREATE_TRAKYV_ACCOUNT_ERROR = 'SET_CREATE_TRAKYV_ACCOUNT_ERROR';
export const RECEIVE_CREATE_TRAKYV_ACCOUNT = 'RECEIVE_CREATE_TRAKYV_ACCOUNT';
export const SET_ERROR_MESSAGE_CREATE_TRAKYV_ACCOUNT = 'SET_ERROR_MESSAGE_CREATE_TRAKYV_ACCOUNT';
export const ADD_CREATED_ACCOUNT = 'ADD_CREATED_ACCOUNT';
export const SET_NEWPW_REQUEST = 'SET_NEWPW_REQUEST';
export const CLEAR_NEWPW_REQUEST = 'CLEAR_NEWPW_REQUEST';
export const REQUEST_TRAKYV_ACCOUNT_PROFILE = 'REQUEST_TRAKYV_ACCOUNT_PROFILE';
export const SET_TRAKYV_ACCOUNT_PROFILE_ERROR = 'SET_TRAKYV_ACCOUNT_PROFILE_ERROR';
export const RECEIVE_TRAKYV_ACCOUNT_PROFILE = 'RECEIVE_TRAKYV_ACCOUNT_PROFILE';
export const ADD_TRAKYV_ACCOUNT_PROFILE = 'ADD_TRAKYV_ACCOUNT_PROFILE';
export const UPDATE_TRAKYV_ACCOUNT_PROFILE = 'UPDATE_TRAKYV_ACCOUNT_PROFILE';
export const REQUEST_RESET_TRAKYV_PASSWORD = 'REQUEST_RESET_TRAKYV_PASSWORD';
export const SET_RESET_TRAKYV_PASSWORD_ERROR = 'SET_RESET_TRAKYV_PASSWORD_ERROR';
export const RECEIVE_RESET_TRAKYV_PASSWORD = 'RECEIVE_RESET_TRAKYV_PASSWORD';

// USER_ROLES_FEATURES
export const REQUEST_USER_ROLES_FEATURES = 'REQUEST_USER_ROLES_FEATURES';
export const SET_FETCH_ROLES_FEATURES_ERROR = 'SET_FETCH_ROLES_FEATURES_ERROR';
export const RECEIVE_ROLES_FEATURES = 'RECEIVE_ROLES_FEATURES';
export const ADD_ROLES_FEATURES = 'ADD_ROLES_FEATURES';

// LISTVELO
export const REQUEST_TRAKYV_LIST_VELO = 'REQUEST_TRAKYV_LIST_VELO';
export const SET_TRAKYV_LIST_VELO_ERROR = 'SET_TRAKYV_LIST_VELO_ERROR';
export const RECEIVE_TRAKYV_LIST_VELO = 'RECEIVE_TRAKYV_LIST_VELO';
export const ADD_TRAKYV_LIST_VELO = 'ADD_TRAKYV_LIST_VELO';
export const UPDATE_VELO = 'UPDATE_VELO';
export const REQUEST_TRAKYV_CREATE_VELO = 'REQUEST_TRAKYV_CREATE_VELO';
export const SET_TRAKYV_CREATE_VELO_ERROR = 'SET_TRAKYV_CREATE_VELO_ERROR';
export const RECEIVE_TRAKYV_CREATE_VELO = 'RECEIVE_TRAKYV_CREATE_VELO';
export const ADD_TRAKYV_CREATED_VELO = 'ADD_TRAKYV_CREATED_VELO';
export const SET_ERROR_MESSAGE_LIST_VELO = 'SET_ERROR_MESSAGE_LIST_VELO';
export const SET_LIST_VELO_ACHIEVED_REQUEST = 'SET_LIST_VELO_ACHIEVED_REQUEST';

// SELECTIONS
export const SELECT_DEVICE_ID = 'SELECT_DEVICE_ID';
// TBD
export const SELECT_FEATURE = 'SELECT_FEATURE';
export const SELECT_GROUP = 'SELECT_GROUP';
export const SELECT_REPLACEMENT_DEVICE_ID = 'SELECT_REPLACEMENT_DEVICE_ID';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const CLEAR_SEARCH_TERM = 'CLEAR_SEARCH_TERM';
export const CLEAR_ALL_SEARCH_TERMS = 'CLEAR_ALL_SEARCH_TERMS';

// Added!
export const SET_ACCOUNT_TO_MOVE_DEVICE_TO = 'SET_ACCOUNT_TO_MOVE_DEVICE_TO';
export const SET_SELECTED_ACCOUNT_FOR_ACTION = 'SET_SELECTED_ACCOUNT_FOR_ACTION';
export const SELECT_DEVICES = 'SELECT_DEVICES';
export const SET_SUPPORT_CASE_TYPE = 'SET_SUPPORT_CASE_TYPE';
export const SELECT_SUPPORT_CASES = 'SELECT_SUPPORT_CASES';
export const SELECT_EQUIPMENT_TYPE = 'SELECT_EQUIPMENT_TYPE';
export const SELECT_DEVICE_TYPE = 'SELECT_DEVICE_TYPE';
export const SELECT_ACCOUNT = 'SELECT_ACCOUNT';
export const SELECT_ACCOUNT_TYPE = 'SELECT_ACCOUNT_TYPE';
export const ADD_DEVICE_UNIQUEID = 'ADD_DEVICE_UNIQUEID';
export const SET_DEVICE_UNIQUEID = 'SET_DEVICE_UNIQUEID';
