// import "react-hot-loader/patch";
// import { AppContainer } from "react-hot-loader";
import React from "react";

// if (process.env.NODE_ENV !== 'production') {
//   const {whyDidYouUpdate} = require('why-did-you-update');
//   whyDidYouUpdate(React);
// }

import ReactDOM from "react-dom";
import ReactGA from "react-ga";

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
// import gaMiddleware from "./middlewares/gaMiddleware";

import { setAppVersion } from "./actions/";

// Internationalization
import { IntlProvider, addLocaleData } from "react-intl";
import areIntlLocalesSupported from "intl-locales-supported";

import fr from "react-intl/locale-data/fr";
import frMessages from "./l10n/fr-messages.json";
// import de from 'react-intl/locale-data/de';
// import deMessages from './l10n/de-messages.json';
// import it from 'react-intl/locale-data/it';
// import itMessages from './l10n/it-messages.json';
import en from "react-intl/locale-data/en";
import enMessages from "./l10n/en-messages.json";
// Reducers
import rootReducer from "./reducers";
import { AUTH_USER } from "./actions/types";

import App from "./App";
import "./app.css";
import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";

addLocaleData(fr);
// addLocaleData(de);
// addLocaleData(it);
addLocaleData(en);

// Onsen UI Styling and Icons
// require("onsenui/stylus/blue-basic-theme.styl");
// require("onsenui/css/onsenui.css");
const getURLParameter = (name) => {
	return (
		decodeURIComponent(
			(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
				// eslint-disable-next-line no-restricted-globals
				location.search
			) || [null, ""])[1].replace(/\+/g, "%20")
		) || null
	);
};

const logger = createLogger();

// const store = createStore(rootReducer,
//   window.devToolsExtension ? window.devToolsExtension() : f => f,
//   process.env.NODE_ENV === 'production' ? applyMiddleware(thunk) : applyMiddleware(thunk, logger)
// );

ReactGA.initialize("UA-106221945-1");

const createStoreWithMiddleware =
	process.env.NODE_ENV === "production"
		? applyMiddleware(thunk)(createStore)
		: applyMiddleware(thunk, logger)(createStore);

const store = createStoreWithMiddleware(rootReducer);

// Some default that will be changed later in case of need (locales!).
let locale = "fr";
let messages = frMessages;

const version = "1.2.3";
console.log("Current version now :", version);
store.dispatch(setAppVersion(version));

/**
 *
 * Use the native Intl.DateTimeFormat if available, or a polyfill if not.
 */
let DateTimeFormat;
if (areIntlLocalesSupported([locale])) {
	DateTimeFormat = global.Intl.DateTimeFormat;
} else {
	const IntlPolyfill = require("intl");
	DateTimeFormat = IntlPolyfill.DateTimeFormat;
	require("intl/locale-data/jsonp/fr");
}

// Get local token
const cfpermission = localStorage.getItem("cfpermission");
//////////////////////////////////////////////////////////
// If we have a token, consider the user to be logged in! //
// if not, route him to the SignIn page                //
////////////////////////////////////////////////////////////

// Are we an admin from admin.chisfleet.com ?
const adminToken = getURLParameter("adtok");
const adminAccountID = getURLParameter("accountID");
if (adminToken) {
	localStorage.setItem(
		"cfpermission",
		JSON.stringify({ token: adminToken, accountID: adminAccountID, userID: "" })
	);
	store.dispatch({ type: AUTH_USER });
} else if (cfpermission) {
	// Update our application state by dispatching an action
	// and BEFORE the rendering of the APP!!! That is the trick.
	store.dispatch({ type: AUTH_USER });
}

// if (cfpermission) {
//   const {permissions, serviceType, accountID} = JSON.parse(localStorage.getItem('cfpermission'));
//   // Update our application state by dispatching an action
//   // and BEFORE the rendering of the APP!!! That is the trick.
//   store.dispatch({ type: AUTH_USER, payload: {permissions, serviceType, accountID} });
// }

const rootElement = document.getElementById("root");
ReactDOM.render(
	<IntlProvider locale={locale} messages={messages}>
		<Provider store={store}>
			<App />
		</Provider>
	</IntlProvider>,
	rootElement
);

// if (module.hot) {
// 	module.hot.accept("./App", () => {
// 		const NextApp = require("./App").default;
// 		ReactDOM.render(
// 			<AppContainer>
// 				<IntlProvider locale={locale} messages={messages}>
// 					<Provider store={store}>
// 						<NextApp />
// 					</Provider>
// 				</IntlProvider>
// 			</AppContainer>,
// 			rootElement
// 		);
// 	});
// }
