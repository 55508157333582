import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../actions';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import compose from 'recompose/compose';

import ons from 'onsenui';
import {List, Button, Icon, ListItem, Modal, Switch, Page} from 'react-onsenui';
import omit from 'lodash/omit';

import { styles, VeloIcon, renderField, TrakyvFooter } from '../helpers';

import NavBar from './NavBar';
import TKPicture from '../components/TKPicture';

import { PAGE_VELO_DECLARATION_VOL, PAGE_VELO_PLAINTE_ID,
	TRAKYV_STATE_NOT_LOST, TRAKYV_STATE_PREACTIVATED, TRAKYV_STATE_ACTIVATED, TRAKYV_STATE_SEEN, TEXT_HTML_GENERAL_CONDITIONS, STATUS_CODE_DEACTIVATION, HTML_TRANSFER_VELO_CONFIRMATION, HTML_TRANSFER_VELO_PROCESS } from '../constants';

import Page_velo_declaration_vol from './Page_velo_declaration_vol';
import Page_velo_plainte_id from './Page_velo_plainte_id';

// (values, dispatch, props)
const handleFormSubmit = (values, dispatch, props) => {
	const {actions} = props;

	return actions.fetchUpdateTKVelo(JSON.stringify(omit(values, ['imgBicycle', 'imgBicycleInvoice'])))
	.then((response) => {
		actions.updateVelo(omit(values, ['imgBicycle', 'imgBicycleInvoice']));
	});
};

const validate = (values) => {
	const errors = {};
	// frame
	if (!values.frame) {
		errors.frame = 'Obligatoire pour des raisons légales. (Si pas de numéro, entrez ZZZZ)';
	}
	// description
	if (!values.description) {
		errors.description = 'Nécessaire';
	} else if (values.description.length < 2) {
		errors.description = 'Au moins 2 caractères';
	}
	// price
	if (!values.price) {
		errors.price = 'Nécessaire';
	} else if (values.price < 0) {
		errors.price = 'Doit être supérieur à 0 CHF';
	}
	// year
	if (!values.year) {
		errors.year = 'Nécessaire';
	} else if (!(values.year.toString().match(/^(19[1-9]\d|20[0-1]\d|2019)$/))) {
		errors.year = 'L\'année doit être entre 1910 et 2019';
	}
	return errors;
};

const TrakyvTransferButton = ({velo, actions}) => {
	return <ListItem>
		<Button modifier='large' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => {
			ons.notification.confirm({
				title: ['Conditions d\'utilisation'],
				messageHTML: HTML_TRANSFER_VELO_CONFIRMATION,
				buttonLabels: ['Non', 'J\'accepte']
			})
			.then((response) => {
				switch (response) {
					case 1:
						ons.notification.confirm({
							title: ['Etes-vous certain de vouloir transférer votre vélo?'],
							messageHTML: HTML_TRANSFER_VELO_PROCESS,
							buttonLabels: ['Non', 'Oui!']
						})
						.then(res2 => {
							switch (res2) {
								case 1:
									return actions.fetchTransferTKVelo(velo.deviceID)
									.then(() => {
										document.location.reload(true);
									})
									.catch(() => (false));
							}
						});
						break;
					default:
						return 0;
				}
			});
		}}
		>
			<Icon icon='ion-forward' size={{default: 28}} style={{color: 'white', marginRight: '10px'}}/>
			<div>
				Transférer {velo.description ? velo.description : ' ce vélo.'}
			</div>
		</Button>
		<div style={{fontSize: '0.7em'}}>
			Transfert lors du changement de propriétaire (vente du vélo)
		</div>
	</ListItem>;
};

const TrakyvActivationButton = ({navigator, velo, actions}) => {
	switch (velo.isLost) {
		case TRAKYV_STATE_NOT_LOST:
			return <ListItem key={velo.isLost}>
				<Button modifier='large' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => {
					ons.notification.confirm({
						title: ['Conditions d\'utilisation'],
						messageHTML: TEXT_HTML_GENERAL_CONDITIONS,
						buttonLabels: ['Non', 'J\'accepte']
					})
					.then((response) => {
						switch (response) {
							case 1:
								navigator.pushPage({component: Page_velo_declaration_vol, key: PAGE_VELO_DECLARATION_VOL});
								return 1;
							default:
								return 0;
						}
					});
				}}
				>
					<Icon icon='ion-search' size={{default: 28}} style={{color: 'white', marginRight: '10px'}}/>
					<div>
						Lancer la recherche
					</div>
				</Button>
			</ListItem>;
		case TRAKYV_STATE_PREACTIVATED:
			return <ListItem key={velo.isLost}>
				<div className='center'>
					<Button key='b1' modifier='large' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px'}} onClick={() => {
						ons.notification.confirm({
							title: ['Rappel des conditions d\'utilisation'],
							messageHTML: TEXT_HTML_GENERAL_CONDITIONS,
							buttonLabels: ['Non', 'J\'accepte']
						})
						.then((response) => {
							switch (response) {
								case 1:
									navigator.pushPage({component: Page_velo_plainte_id, key: PAGE_VELO_PLAINTE_ID});
									return 1;
								default:
									return 0;
							}
						});
					}}
					>
						<Icon icon='ion-document-text' size={{default: 28}} style={{color: 'white', marginRight: '10px'}}/>
						<div>
							Entrez votre numéro de plainte
						</div>
					</Button>
					<Button key='b2' modifier='large' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => {
						ons.notification.confirm({
							title: 'Alerte',
							message: 'Etes-vous certain de vouloir désactiver la recherche?',
							buttonLabels: ['Non', 'Oui']
						})
						.then((choice) => {
							switch (choice) {
								case 1:
									return actions.fetchUpdateTKVeloState(JSON.stringify({deviceID: velo.deviceID, statusCode: STATUS_CODE_DEACTIVATION}))
											.then((response) => {
												actions.updateVelo({isLost: TRAKYV_STATE_NOT_LOST});
											});
								default:
									return 0;
							}
						});
					}}
					>
						<Icon icon='ion-power' size={{default: 28}} style={{color: 'white', marginRight: '10px'}}/>
						<div>
							Désactiver la recherche en cours
						</div>
					</Button>
				</div>
			</ListItem>;
		case TRAKYV_STATE_ACTIVATED:
			return <ListItem key={velo.isLost}>
				<Button modifier='large' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => {
					ons.notification.confirm({
						title: 'Alerte',
						message: 'Etes-vous certain de vouloir désactiver la recherche?',
						buttonLabels: ['Non', 'Oui']
					})
					.then((choice) => {
						switch (choice) {
							case 1:
								return actions.fetchUpdateTKVeloState(JSON.stringify({deviceID: velo.deviceID, statusCode: STATUS_CODE_DEACTIVATION}))
										.then((response) => {
											actions.updateVelo({isLost: TRAKYV_STATE_NOT_LOST});
										});
							default:
								return 0;
						}
					});
				}}
				>
					<Icon icon='ion-power' size={{default: 28}} style={{color: 'white', marginRight: '10px'}}/>
					<div>
						Désactiver la recherche en cours
					</div>
				</Button>
			</ListItem>;
		case TRAKYV_STATE_SEEN:
			return <ListItem key={velo.isLost}>
				<div className='center'>
					<Button key='b2' modifier='large' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => {
						ons.notification.confirm({
							title: 'Alerte',
							message: 'Etes-vous certain de vouloir désactiver la recherche?',
							buttonLabels: ['Non', 'Oui']
						})
						.then((choice) => {
							switch (choice) {
								case 1:
									return actions.fetchUpdateTKVeloState(JSON.stringify({deviceID: velo.deviceID, statusCode: STATUS_CODE_DEACTIVATION}))
											.then((response) => {
												actions.updateVelo({isLost: TRAKYV_STATE_NOT_LOST});
											});
								default:
									return 0;
							}
						});
					}}
					>
						<Icon icon='ion-power' size={{default: 28}} style={{color: 'white', marginRight: '10px'}}/>
						<div>
							Désactiver la recherche en cours
						</div>
					</Button>
				</div>
			</ListItem>;
		default:
			return null;
	}
};

const renderBooleanField = ({ input, meta: { touched, error }, ...custom }) => {
	console.log('isElectric field', [input, custom]);
	return (<ListItem>
		<div className='list__item__center'>
			Vélo électrique?
		</div>
		<div className='list__item__right'>
			<Switch {...input} {...custom} onChange={e => {
				input.onChange(e.target.checked);
			}} name='isElectric' checked={input.value ? true : false}/>
		</div>
	</ListItem>);
};

let Page_velo_editor = (props) => {
	const {handleSubmit, submitting, navigator, listVelo, isElectric, velo, valid, actions} = props;
	return (
		<Page
			renderToolbar={() => <NavBar title='Profil du vélo' navigator={navigator} backButton={true} />}
			renderModal={() => ( // Modal for account in creation (waiting for server answer)
				<Modal
					isOpen={listVelo.isFetching || listVelo.isInvalid || listVelo.requestAchieved}
				>
					<div style={{display: 'flex', justifyContent: 'center'}}>
					{
						listVelo.isFetching &&
						<section style={{margin: '16px'}}>
							<p style={{opacity: 0.6}}>
								Demande en cours, merci de patienter.
							</p>
						</section>
					}
					{
						listVelo.requestAchieved &&
						<section style={{margin: '16px'}}>
							<p style={{opacity: 0.6}}>
								Demande aboutie, vérifier votre boîte email pour trouver la confirmation.
							</p>
							<Button
								navigator={navigator}
								onClick={() => {
									actions.setListVeloAchievedRequest(false);
									navigator.popPage();
								}}
							>
								Retour
							</Button>
						</section>
					}
					{
						listVelo.isInvalid &&
						<section style={{margin: '16px'}}>
							<p style={{opacity: 0.6}}>
								Problème réseau, merci d'essayez à nouveau. Vérifier vos données et assurez-vous d'être connecté à internet.
							</p>
							<Button
								navigator={navigator}
								onClick={() => {
									actions.receiveTKCreateVelo();
								}}
							>
								Retour
							</Button>
						</section>
					}
					</div>
				</Modal>)
			}
		>
			<div style={styles.profileCard}>
				<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', wrap: 'nowrap'}}>
					<TKPicture imgFieldName='imgBicycleInvoice' viewType='rect' velo={velo} actions={actions} />
					<TKPicture imgFieldName='imgBicycle' viewType='circle' velo={velo} actions={actions} />
				</div>
				<div style={styles.profileName}>
					<VeloIcon {...props} />
					<span style={{marginLeft: '10px'}}>{velo.description}</span>
				</div>
				<div style={styles.profileDesc}>{velo.make} {velo.model}</div>
				<div style={styles.profileId}>TrakyvID {velo.uniqueID}</div>
			</div>
			<List modifier='inset' style={{marginTop: '8px'}}>
				<Field name='description' component={renderField} type='text' placeholder='Description du vélo'/>
				<Field name='make' component={renderField} type='text' placeholder='Marque'/>
				<Field name='model' component={renderField} type='text' placeholder='Modèle'/>
				<Field name='price' component={renderField} type='number' placeholder='Prix (CHF)'/>
				<Field name='year' component={renderField} type='text' placeholder={'Année d\'achat'}/>
				<Field name='frame' component={renderField} type='text' placeholder='Numéro de cadre'/>
				<Field name='lock' component={renderField} type='text' placeholder='Numéro de cadenas'/>
				<Field name='color' component={renderField} type='text' placeholder='Couleur'/>
				<Field name='insuranceName' component={renderField} type='text' placeholder='Assurance'/>
				<Field name='insuranceNumber' component={renderField} type='text' placeholder='Numéro de police'/>
				<Field name='isElectric' component={renderBooleanField} type='checkbox'/>
				{
					isElectric &&
					<div>
						<Field name='licensePlate' component={renderField} type='text' placeholder='Numéro de plaque'/>
						<Field name='engine' component={renderField} type='text' placeholder='Numéro de moteur'/>
						<Field name='battery' component={renderField} type='text' placeholder='Numéro de batterie'/>
						<Field name='keys' component={renderField} type='text' placeholder='Numéro de clé'/>
					</div>
				}
				<ListItem>
					<div className='center'>
						{
							!valid && <div className='list__item__title' style={{color: 'red', fontSize: '0.75em', fontWeight: 'bold', textAlign: 'left' }}>
								Vérifier les champs plus hauts!
							</div>
						}
						<div className='list__item__subtitle' style={{}}>
							<Button type='submit' modifier='large'
								navigator={navigator}
								onClick={handleSubmit(handleFormSubmit)}
								disabled={submitting}
							>
								Sauver
							</Button>
						</div>
					</div>
				</ListItem>
			</List>
			<List modifier='inset' style={{marginTop: '8px'}}>
				<TrakyvTransferButton {...props} />
				<TrakyvActivationButton {...props} />
			</List>
			<TrakyvFooter toast={false} actions={actions}/>
		</Page>
	);
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
Page_velo_editor = reduxForm({
	form: 'velo_editor', // a unique identifier for this form
	validate
})(Page_velo_editor);

const selector = formValueSelector('velo_editor');

const hoc = compose(
	connect(
		(state, props) => ({
			isElectric: selector(state, 'isElectric'),
			listVelo: state.listVelo,
			velo: state.listVelo[state.selections.selectedDeviceID],
			initialValues: state.listVelo[state.selections.selectedDeviceID]
		}),
		(dispatch) => ({
			actions: bindActionCreators(Actions, dispatch)
		})
	)
);

export default hoc(Page_velo_editor);
