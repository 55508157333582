import uniq from 'lodash/uniq';
import {
	SELECT_FEATURE, SET_DEVICE_UNIQUEID, ADD_DEVICE_UNIQUEID,
	SELECT_ACCOUNT_TYPE, SELECT_ACCOUNT, SELECT_EQUIPMENT_TYPE, SELECT_SUPPORT_CASES, SET_SUPPORT_CASE_TYPE, SELECT_DEVICE_TYPE, SELECT_GROUP, SELECT_DEVICES, SELECT_REPLACEMENT_DEVICE_ID,
	SET_SELECTED_ACCOUNT_FOR_ACTION, SELECT_DEVICE_ID, SET_SEARCH_TERM, CLEAR_SEARCH_TERM, CLEAR_ALL_SEARCH_TERMS, SET_ACCOUNT_TO_MOVE_DEVICE_TO} from '../actions/types';

const initialState = {
	selectedDeviceID: null,
	// Stil not used
	selectedAccountType: null, selectedDeviceType: null, selectedGroupID: null,
	selectedDevice: null, selectedDevices: [], selectedReplacementDeviceID: null,
	selectedAccountID: null,
	selectedAccountForAction: null,
	accountToMoveTo: null,
	uidList: [],
	uidValue: '',
	searchDevice: ''
};

const selections = (state = initialState, action) => {
	switch (action.type) {
		case SELECT_DEVICE_ID:
			return {
				...state,
				selectedDeviceID: action.deviceID
			};
		// below still not used!
		case SELECT_FEATURE:
			return {
				...state, selectedFeature: action.selectedFeature
			};
		case SET_DEVICE_UNIQUEID:
			return {
				...state, uidValue: action.uidValue
			};
		case ADD_DEVICE_UNIQUEID:
			return {
				...state, uidList: uniq([...state.uidList, action.uid])
			};
		case SELECT_ACCOUNT_TYPE:
			return {
				...state, selectedAccountType: action.selectedAccountType
			};
		case SELECT_ACCOUNT:
			return {
				...state, selectedAccountID: action.accountID
			};
		case SELECT_DEVICE_TYPE:
			return {
				...state, selectedDeviceType: action.deviceType
			};
		case SELECT_EQUIPMENT_TYPE:
			return {
				...state, selectedDeviceType: action.equipmentType, equipmentType: action.equipmentType, uidList: []
			};
		case SELECT_SUPPORT_CASES:
			return {
				...state, supportCases: action.supportCases
			};
		case SELECT_DEVICES:
			return {
				...state,
				selectedDevices: action.devices
			};
		case SELECT_REPLACEMENT_DEVICE_ID:
			return {
				...state,
				selectedReplacementDeviceID: action.deviceID
			};
		case SELECT_GROUP:
			return {
				...state,
				selectedGroupID: action.group
			};
		case SET_SUPPORT_CASE_TYPE:
			return {
				...state, supportType: action.supportType
			};
		case SET_SELECTED_ACCOUNT_FOR_ACTION:
			return {
				...state,
				selectedAccountForAction: action.selectedAccountID
			};
		case SET_ACCOUNT_TO_MOVE_DEVICE_TO:
			return {
				...state,
				accountToMoveTo: action.selectedAccountID
			};
		case SET_SEARCH_TERM:
			return {
				...state,
				[action.term]: action.value.toLowerCase()
			};
		case CLEAR_SEARCH_TERM:
			return {
				...state,
				[action.term]: ''
			};
		case CLEAR_ALL_SEARCH_TERMS:
			const termKeys = Object.keys(state).filter(key => key.match(/search*/));
			const termsObject = {};
			termKeys.map(key => (termsObject[key] = ''));
			return {
				...state,
				...termsObject
			};
		default:
			return state;
	}
};

export default selections;
