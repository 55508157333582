import React from 'react';
import {findDOMNode} from 'react-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../actions';
import { Field, reduxForm } from 'redux-form';
import compose from 'recompose/compose';

import {List, Button, ListItem, ListHeader, Modal, Page} from 'react-onsenui';
import { renderField, normalizeTrakyvID, TrakyvFooter } from '../helpers';

import NavBar from './NavBar';

const handleFormSubmit = (props) => ({uniqueID, pin}) => {
	const {actions, navigator} = props;

	return actions.fetchTKCreateVelo(JSON.stringify({uniqueID: uniqueID.replace(/ /g, ''), pin}))
	.then((response) => {
		actions.setListVeloAchievedRequest(false);
		navigator.popPage();
	})
	.catch((error) => {
		console.log(error);
	});
};

const validate = (values) => {
	const errors = {};
	// uniqueID
	if (!values.uniqueID) {
		errors.uniqueID = 'Nécessaire';
	} else if (!(/^181[1-2]00[0-2][0-9][0-9][0-2][0-9][0-9]$/i).test(values.uniqueID)) {
		errors.uniqueID = 'Code Trakyv non valable';
	}
	// pin
	if (!values.pin) {
		errors.pin = 'Nécessaire';
	} else if (!(/^[0-9]{4}$/i).test(values.pin)) {
		errors.pin = 'Code PIN non valable';
	}

	return errors;
};

let _refTKID;

let Page_trakyvID_input = (props) => {
	const {handleSubmit, submitting, navigator, listVelo, actions} = props;
	return (
		<Page
			renderToolbar={() => <NavBar title="Ajout d'un vélo" navigator={navigator} backButton={true} right={false}/>}
			renderModal={() => ( // Modal for account in creation (waiting for server answer)
				<Modal
					isOpen={listVelo.isFetching || listVelo.isInvalid || listVelo.error}
				>
					<div style={{display: 'flex', justifyContent: 'center'}}>
					{
						listVelo.isFetching &&
						<section style={{width: '80%'}}>
							<p>
								Vérification et enregistrement en cours, merci de patienter...
							</p>
						</section>
					}
					{
						listVelo.isInvalid &&
						<section style={{width: '80%'}}>
							<p>
								Problème réseau ou dans les données entrées. <br/>
								Vérifiez votre numéro TrakyvID ainsi que votre code PIN et essayer à nouveau. <br/>
							</p>
							<Button
								navigator={navigator}
								onClick={() => {
									actions.receiveTKCreateVelo();
									actions.setErrorMessageTKListVelo('');
								}}
							>
								Retour
							</Button>
						</section>
					}
					</div>
				</Modal>)
			}
		>
			<List modifier='inset' style={{marginTop: '8px'}}
				renderHeader={() => <ListHeader>
					Information disponible dans votre mode d'emploi
				</ListHeader>}
			>
				<Field ref={node => (_refTKID = node)} name='uniqueID' component={renderField} type='text' placeholder='Numéro de puce Trakyv' normalize={normalizeTrakyvID}/>
				<Field name='pin' component={renderField} type='number' placeholder='Code PIN'/>
				<ListItem>
					<Button type='submit' modifier='large'
						navigator={navigator}
						onClick={handleSubmit(handleFormSubmit(props))}
						disabled={submitting}
					>
						Ajouter!
					</Button>
				</ListItem>
			</List>
			<TrakyvFooter />
		</Page>
	);
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
Page_trakyvID_input = reduxForm({
	form: 'trakyvIDInput', // a unique identifier for this form
	validate
	// asyncValidate,
	// asyncBlurFields: ['uniqueID']
})(Page_trakyvID_input);

const hoc = compose(
	connect(
		(state, props) => ({
			listVelo: state.listVelo
		}),
		(dispatch) => ({
			actions: bindActionCreators(Actions, dispatch)
		})
	)
);

export default hoc(Page_trakyvID_input);
