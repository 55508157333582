import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "./actions";
import compose from "recompose/compose";
import { Navigator } from "react-onsenui";

import {
	PAGE_MAIN_FEATURES_MENU,
	PAGE_SIGNIN,
	PAGE_SET_NEW_PASSWORD,
} from "./constants";

import MainPage from "./containers/MainPage";
import SignIn from "./components/auth/SignIn";
import Page_set_new_password from "./containers/Page_set_new_password";

const getURLParameter = (name) => {
	return (
		decodeURIComponent(
			(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
				// eslint-disable-next-line no-restricted-globals
				location.search
			) || [null, ""])[1].replace(/\+/g, "%20")
		) || null
	);
};

const getPageFromAuth = ({ auth, page, token }) => {
	if (!auth && page === "npw" && token) {
		return {
			component: Page_set_new_password,
			key: PAGE_SET_NEW_PASSWORD,
			token,
		};
	} else if (auth) {
		return { component: MainPage, key: PAGE_MAIN_FEATURES_MENU };
	} else {
		return { component: SignIn, key: PAGE_SIGNIN };
	}
};

class App extends React.Component {
	renderPage(route, navigator) {
		const props = route.props || {};
		props.navigator = navigator;

		return React.createElement(route.component, props);
	}

	render() {
		const token = getURLParameter("token");
		const page = getURLParameter("page");
		// What is initial route?
		// If authenticated then PAGE_MAIN_FEATURES_MENU
		// If no page in URL and not authenticated then PAGE_SIGNIN
		// If page in URL and page is NewPassword and token in URL then PAGE_RESET_PASSWORD
		return (
			<Navigator
				initialRoute={getPageFromAuth({
					auth: this.props.authenticated,
					page,
					token,
				})}
				renderPage={this.renderPage}
			/>
		);
	}
}

const hoc = compose(
	connect(
		(state, props) => ({
			authenticated: state.auth.authenticated,
		}),
		(dispatch) => ({
			actions: bindActionCreators(Actions, dispatch),
		})
	)
);

export default hoc(App);
