import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
// import { reducer as accountPage } from 'redux-form';
import selections from "./selections";
import account from "./account";
import listVelo from "./listVelo";
import auth from "./auth";
// import ui from "./ui";
// import deviceTypes from './deviceTypes';
// import devices from './devices';
// import mapData from './mapData';

const appReducer = combineReducers({
	form,
	auth,
	account,
	listVelo,
	// devices,
	selections,
	// ui,
	// deviceTypes,
	// mapData
});

const rootReducer = (state, action) => {
	if (action.type === "UNAUTH_USER") {
		localStorage.removeItem("cfpermission");
		//return appReducer(undefined, action);
	}

	return appReducer(state, action);
};

export default rootReducer;
