import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {Icon, Input, ListItem} from 'react-onsenui';
import {notification} from 'onsenui';
import { Image, CloudinaryContext, Transformation } from 'cloudinary-react';

import {
	DEFAULT_LOGO_BADGE_IMAGE, DEFAULT_FOOTER_IMAGE,
	TRAKYV_STATE_NOT_LOST, TRAKYV_STATE_PREACTIVATED, TRAKYV_STATE_ACTIVATED, TRAKYV_STATE_SEEN,
	COLOR_TRAKYV_BASE, COLOR_TRAKYV_STATE_PREACTIVATED, COLOR_TRAKYV_STATE_ACTIVATED, COLOR_TRAKYV_STATE_SEEN, COLOR_TRAKYV_STATE_NOT_LOST
} from './constants';

export const VeloIcon = ({velo}) => {
	switch (velo.isLost) {
		case TRAKYV_STATE_PREACTIVATED:
			return <Icon style={{color: COLOR_TRAKYV_STATE_PREACTIVATED}} size={{default: 40}} icon='ion-search'/>;
		case TRAKYV_STATE_ACTIVATED:
			return <Icon style={{color: COLOR_TRAKYV_STATE_ACTIVATED}} size={{default: 40}} icon='ion-search'/>;
		case TRAKYV_STATE_SEEN:
			return <Icon style={{color: COLOR_TRAKYV_STATE_SEEN}} size={{default: 40}} icon='ion-search'/>;
		case TRAKYV_STATE_NOT_LOST:
		default:
			return <Icon style={{color: COLOR_TRAKYV_STATE_NOT_LOST}} size={{default: 40}} icon='ion-android-bicycle'/>;
	}
};

export const renderField = ({ input, placeholder, type, meta: { touched, error }, ...custom }) => (
	<ListItem>
		<div className='center'>
			<div className='list__item__title'>
				{placeholder}
			</div>
			<div className='list__item__subtitle' style={{}}>
				<Input style={{minWidth: '100%'}} {...input} placeholder={placeholder} type={type} autocapitalize='sentences'
					errorText={touched && error} {...custom}/>
				{
					touched && (error &&
						<div style={{color: 'red', fontSize: '0.75em', fontWeight: 'bold', textAlign: 'left' }}>
							{error}
						</div>)
				}
			</div>
		</div>
	</ListItem>
);

export const renderDatePicker = ({input, placeholder, meta: {touched, error}, actions, ui, ...custom }) => {
	moment.locale('fr_CH');
	const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
	if (isMobile) {
		return <ListItem>
			<div className='center'>
				<div className='list__item__title'>
					{placeholder}
				</div>
				<div className='list__item__subtitle' style={{}}>
					<Input style={{minWidth: '100%'}} {...input} placeholder={placeholder} type='date'
						errorText={touched && error} {...custom}/>
					{
						touched && (error &&
							<div style={{color: 'red', fontSize: '0.75em', fontWeight: 'bold', textAlign: 'left' }}>
								{error}
							</div>)
					}
				</div>
			</div>
		</ListItem>;
	} else {
		return <ListItem tappable style={ui.uiDatePickerIsOpen ? {marginBottom: '270px'} : null} >
			<div className='center'>
				<div className='list__item__title'>
					{placeholder}
				</div>
				<div className='list__item__subtitle' onClick={(e) => {
					if (e.target.type) { // a hack to close the ListItem marginBottom of 270 px!
						actions.uiSetDatePicker();
					}
				}}>
					<DatePicker {...input} selected={input.value ? moment(input.value) : null} {...custom}
						showMonthDropdown
						showYearDropdown
						dropdownMode='select'
						minDate={moment().add(-99, 'years')}
						maxDate={moment().add(-18, 'years')}
						onClickOutside={(e) => {
							actions.uiClearDatePicker();
						}}
						onSelect={(e) => {
							actions.uiClearDatePicker();
						}}
					/>
					{
						touched && (error &&
							<div style={{color: 'red', fontSize: '0.75em', fontWeight: 'bold', textAlign: 'left' }}>
								{error}
							</div>)
					}
				</div>
			</div>
		</ListItem>;
	}
};

export const calculateAge = birthday => { // birthday is a date
	const ageDifMs = Date.now() - birthday.getTime();
	const ageDate = new Date(ageDifMs); // miliseconds from epoch
	const ageOfBirthday = ageDate.getUTCFullYear() - 1970;
	return ageOfBirthday > 18 && ageOfBirthday < 101 ? ageOfBirthday : 0;
};

export const normalizePhone = value => {
	if (!value) {
		return value;
	}

	const onlyNums = value.replace(/[^\d]/g, '');
	if (onlyNums.length <= 3) {
		return onlyNums;
	}
	if (onlyNums.length <= 7) {
		return `${onlyNums.slice(0, 3)}${onlyNums.slice(3)}`;
	}
	return `${onlyNums.slice(0, 3)}${onlyNums.slice(3, 6)}${onlyNums.slice(6, 10)}`;
};

export const normalizeTrakyvID = value => {
	if (!value) {
		return value;
	}
	const onlyNums = value.replace(/[^\d]/g, '');
	if (onlyNums.length <= 3) {
		return onlyNums;
	}
	if (onlyNums.length <= 6) {
		return `${onlyNums.slice(0, 3)}${onlyNums.slice(3)}`;
	}
	if (onlyNums.length <= 9) {
		return `${onlyNums.slice(0, 3)}${onlyNums.slice(3, 6)}${onlyNums.slice(6)}`;
	}
	return `${onlyNums.slice(0, 3)}${onlyNums.slice(3, 6)}${onlyNums.slice(6, 9)}${onlyNums.slice(9, 12)}`;
};

export const lower = value => value && value.toLowerCase();

export const styles = {
	profileCard: {
		width: '100%',
		textAlign: 'center',
		color: 'white',
		padding: '20px 0',
		lineHeight: '1.4',
		backgroundColor: COLOR_TRAKYV_BASE,
		textShadow: 'rgba(0, 0, 0, 0.4) 0px 1px 0px'
	},
	profileName: {
		margin: '10px 0 0 0',
		fontWeight: '600',
		fontSize: '26px'
	},
	profileId: {
		fontSize: '14px',
		opacity: '0.6'
	},
	profileDesc: {
		fontSize: '18px',
		opacity: '0.7',
		width: '90%',
		textAlign: 'center',
		margin: '0 auto'
	},
	imageRelative90: {
		width: '90%',
		margin: 'auto',
		display: 'block',
		opacity: '1'
	},
	imageRelative50: {
		width: '50%',
		margin: 'auto',
		display: 'block'
	},
	titreDeclaration: {
		color: 'red',
		textAlign: 'center',
		fontSize: '15px',
		margin: '10px'
	},
	titreFormulaire: {
		color: 'black',
		textAlign: 'center',
		fontSize: '15px',
		margin: '10px'
	}
};

export const TrakyvFooter = () => {
	return <CloudinaryContext cloudName='trakyv-com'>
				<Image secure='true' publicId={DEFAULT_FOOTER_IMAGE} style={styles.imageRelative90}>
					<Transformation crop='limit' fetch_format='auto' quality='auto'/>
				</Image>
			</CloudinaryContext>;
};

export const TrakyvHeader = ({appVersion, actions}) => {
	return <CloudinaryContext cloudName='trakyv-com'>
				<Image secure='true' publicId={DEFAULT_LOGO_BADGE_IMAGE} style={styles.imageRelative50} onClick={(e) => {
					notification.alert({
						title: 'A propos',
						message: 'Version: ' + appVersion,
						buttonLabels: ['Reload', 'OK']
					})
					.then((response) => {
						console.log(response);
						switch (response) {
							case 0:
								document.location.reload(true);
								break;
							default:
							break;
						}
					});
				}}>
					<Transformation crop='limit' fetch_format='auto' quality='auto' width='2000' height='1500'/>
				</Image>
			</CloudinaryContext>;
};

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const asyncValidate = (values /*, dispatch */) => {
	return sleep(1000).then(() => {
		// simulate server latency
		if (['181255255255', 'paul', 'george', 'ringo'].includes(values.uniqueID)) {
			throw { uniqueID: 'That uniqueID is taken'};
		}
	});
};
